import { globalConstants } from '../constants';

export function setItem(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}

export function getItem(key) {
	try {
		return JSON.parse(localStorage.getItem(key));
	} catch (e) {
		return null;
	}
}

export function getUser() {
	return JSON.parse(localStorage.getItem(globalConstants.LOCAL_STORAGE_USER));
}

export function removeItem(key) {
	localStorage.removeItem(key);
}
