import * as promoCodeService from '../services/promoCodeService';
import { promoCodeConstants } from '../constants';
import { sendError } from '../services/errorService';

export function getPromoCodes(companyId) {
	return async (dispatch) => {
		dispatch(getPromoCodesRequest());
		let result = await promoCodeService.getPromoCodes(companyId);
		if (result.OK) {
			dispatch(getPromoCodesSuccess(result.Data));
		} else {
			dispatch(getPromoCodesfailure());
		}
	};

	function getPromoCodesRequest() {
		return { type: promoCodeConstants.GET_ALL_PROMO_CODES_REQUEST };
	}
	function getPromoCodesSuccess(result) {
		return { type: promoCodeConstants.GET_ALL_PROMO_CODES_SUCCESS, result };
	}
	function getPromoCodesfailure() {
		return { type: promoCodeConstants.GET_ALL_PROMO_CODES_FAILURE };
	}
}

export function addPromoCode(data) {
	return async (dispatch) => {
		dispatch(addPromoCodeRequest());
		let result = await promoCodeService.addPromoCode(data);
		if (result.OK) {
			dispatch(addPromoCodeSuccess(result.Data));
		} else {
			try {
				dispatch(addPromoCodesFailure(result.ErrorText));
			} catch (error) {
				sendError('PromoCodeActions - addPromoCode()', error);
			}
		}
	};

	function addPromoCodeRequest() {
		return { type: promoCodeConstants.ADD_PROMO_CODE_REQUEST };
	}
	function addPromoCodeSuccess(result) {
		return {
			type: promoCodeConstants.ADD_PROMO_CODE_SUCCESS,
			result
		};
	}
	function addPromoCodesFailure(message) {
		return { type: promoCodeConstants.ADD_PROMO_CODE_FAILURE, message };
	}
}

export function editPromoCode(data) {
	return async (dispatch) => {
		dispatch(editPromoCodeRequest());
		let result = await promoCodeService.editPromoCode(data);
		if (result.OK) {
			dispatch(editPromoCodeSuccess(result.Data));
		} else {
			dispatch(editPromoCodeFaliure(result.ErrorText));
		}
	};

	function editPromoCodeRequest() {
		return { type: promoCodeConstants.EDIT_PROMO_CODE_REQUEST };
	}
	function editPromoCodeSuccess(result) {
		return {
			type: promoCodeConstants.EDIT_PROMO_CODE_SUCCESS,
			result
		};
	}
	function editPromoCodeFaliure(message) {
		return { type: promoCodeConstants.EDIT_PROMO_CODE_FAILURE, message };
	}
}

export function deletePromoCode(promoCodeId) {
	return async (dispatch) => {
		dispatch(deletePromoCodeRequest());
		let result = await promoCodeService.deletePromoCode(promoCodeId);

		if (result.OK) {
			dispatch(deletePromoCodeSuccess(result.Data));
		} else {
			dispatch(deletePromoCodeFaliure(result.ErrorText));
		}
	};

	function deletePromoCodeRequest() {
		return { type: promoCodeConstants.DELETE_PROMO_CODE_REQUEST };
	}

	function deletePromoCodeSuccess(result) {
		return { type: promoCodeConstants.DELETE_PROMO_CODE_SUCCESS, result };
	}

	function deletePromoCodeFaliure(message) {
		return { type: promoCodeConstants.DELETE_PROMO_CODE_FALIURE, message };
	}
}
