import { globalConstants } from '../constants';
import { getItem, mapCompanies } from '../helpers';
import { companyConstants } from '../constants/companyConstants';

const userAttributes = getItem(globalConstants.LOCAL_STORAGE_USER) && getItem(globalConstants.LOCAL_STORAGE_USER).userAttributes;

export function companyReducer(
	state = {
		companies:
			userAttributes && userAttributes?.role?.roleName !== 'headAdmin' && userAttributes?.companies?.length > 0
				? mapCompanies(userAttributes.companies, 'initial')
				: []
	},
	action = {}
) {
	switch (action.type) {
		case companyConstants.GET_COMPANIES_REQUEST:
			return {
				...state,
				companiesLoading: true,
				companiesFailed: false
			};
		case companyConstants.GET_COMPANIES_SUCCESS:
			return {
				...state,
				companiesLoading: false,
				companies: mapCompanies(action.result, 'GET_COMPANIES_SUCCESS'),
				companiesFailed: false
			};
		case companyConstants.GET_COMPANIES_FAILURE:
			return {
				...state,
				companiesLoading: false,
				companiesFailed: true
			};
		case companyConstants.SET_COMPANIES:
			return {
				...state,
				companies: mapCompanies(action.result, 'SET_COMPANIES')
			};
		//getCompany
		case companyConstants.GET_COMPANY_REQUEST:
			return {
				...state,
				companyLoading: true,
				companyFailed: false,
				selectedCompany: null
			};
		case companyConstants.GET_COMPANY_SUCCESS:
			return {
				...state,
				companyLoading: false,
				selectedCompany: { ...action.result, value: action.result.id, label: action.result.companyName, userStatus: action.result.userStatus }
			};
		case companyConstants.GET_COMPANY_FAILURE:
			return {
				...state,
				companyLoading: false,
				companyFailed: true
			};
		case companyConstants.ADD_COMPANY_REQUEST:
			return {
				...state,
				addCompanyLoading: true,
				addedCompany: false,
				addCompanyFailedMessage: ''
			};
		case companyConstants.ADD_COMPANY_SUCCESS:
			let addCompaniesCopy = [];
			if (Array.isArray(action.result)) {
				addCompaniesCopy = action.result;
			} else {
				addCompaniesCopy.push(action.result);
			}
			return {
				...state,
				addCompanyLoading: false,
				addedCompany: true,
				companies: mapCompanies(addCompaniesCopy, 'ADD_COMPANY_SUCCESS')
			};
		case companyConstants.ADD_COMPANY_FAILURE:
			return {
				...state,
				addCompanyLoading: false,
				addedCompany: false,
				addCompanyFailedMessage: action.message
			};
		case companyConstants.EDIT_COMPANY_REQUEST:
			return {
				...state,
				editCompanyLoading: true,
				editedCompany: false,
				editCompanyFailedMessage: ''
			};
		case companyConstants.EDIT_COMPANY_SUCCESS:
			let companiesCopy = [];
			if (Array.isArray(action.result)) {
				companiesCopy = action.result;
			} else {
				companiesCopy.push(action.result);
			}
			return {
				...state,
				editCompanyLoading: false,
				editedCompany: true,
				companies: mapCompanies(companiesCopy, 'EDIT_COMPANY_SUCCESS'),
				company: companiesCopy?.find((com) => com.userStatus === 'active')
			};
		case companyConstants.EDIT_COMPANY_FAILURE:
			return {
				...state,
				editedCompany: false,
				editCompanyLoading: false,
				editedCompanyFailedMessage: action.message
			};
		//resetCompanyAddEditState
		case companyConstants.RESET_COMPANY_ADD_EDIT_STATE:
			return {
				...state,
				editedCompany: false,
				addedCompany: false
			};
		case companyConstants.DELETE_COMPANY_REQUEST:
			return {
				...state,
				deletedCompany: false,
				deleteCompanyFailedMessage: ''
			};
		case companyConstants.DELETE_COMPANY_SUCCESS:
			return {
				...state,
				deletedCompany: true,
				companies: mapCompanies(action.result, 'DELETE_COMPANY_SUCCESS')
			};
		case companyConstants.DELETE_COMPANY_FAILURE:
			return {
				...state,
				deletedCompany: false,
				deleteCompanyFailedMessage: action.message
			};
		//changeWorkerActiveCompany
		case companyConstants.CHANGE_WORKER_ACTIVE_COMPANY_REQUEST:
			return {
				...state,
				changingWorkerActiveCompanyLoading: true,
				workerCompanyChanged: false,
				workerCompanyChangingFailed: false
			};
		case companyConstants.CHANGE_WORKER_ACTIVE_COMPANY_SUCCESS:
			return {
				...state,
				changingWorkerActiveCompanyLoading: false,
				workerCompanyChanged: true,
				companies: mapCompanies(action.result, 'CHANGE_WORKER_ACTIVE_COMPANY_SUCCESS')
			};
		case companyConstants.CHANGE_WORKER_ACTIVE_COMPANY_FAILURE:
			return {
				...state,
				changingWorkerActiveCompanyLoading: false,
				workerCompanyChangingFailed: true
			};
		default:
			return {
				...state
			};
	}
}
