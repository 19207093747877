import axios from 'axios';
import { createOKResponse, createErrorResponse } from './responses';
import config from './config';
import { getToken } from './authService';

export async function getCategories(token) {
	try {
		const tokenResponse = token ? token : await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: tokenResponse
			},
			url: `${config.url}/category/get-all`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.get_categories');
	}
}

export async function addCategory(categoryName, iconName) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/category/add`,
			data: { categoryName, iconName }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.add_category');
	}
}

export async function editCategory(id, categoryName, iconName) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/category/edit/${id}`,
			data: { id, categoryName, iconName }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.edit_category');
	}
}

export async function deleteCategory(id) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/category/delete/${id}`,
			data: { id }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, error.delete_category_error);
	}
}
