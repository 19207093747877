export const authConstants = {
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',

	LOGIN_GOOGLE_REQUEST: 'LOGIN_GOOGLE_REQUEST',
	LOGIN_GOOGLE_SUCCESS: 'LOGIN_GOOGLE_SUCCESS',
	LOGIN_GOOGLE_FAILURE: 'LOGIN_GOOGLE_FAILURE',

	LOGIN_APPLE_REQUEST: 'LOGIN_APPLE_REQUEST',
	LOGIN_APPLE_SUCCESS: 'LOGIN_APPLE_SUCCESS',
	LOGIN_APPLE_FAILURE: 'LOGIN_APPLE_FAILURE',

	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

	SIGNUP_REQUEST: 'SIGNUP_REQUEST',
	SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
	SIGNUP_FAILURE: 'SIGNUP_FAILURE',

	SIGNUP_GOOGLE_REQUEST: 'SIGNUP_GOOGLE_REQUEST',
	SIGNUP_GOOGLE_SUCCESS: 'SIGNUP_GOOGLE_SUCCESS',
	SIGNUP_GOOGLE_FAILURE: 'SIGNUP_GOOGLE_FAILURE',

	SIGNUP_APPLE_REQUEST: 'SIGNUP_APPLE_REQUEST',
	SIGNUP_APPLE_SUCCESS: 'SIGNUP_APPLE_SUCCESS',
	SIGNUP_APPLE_FAILURE: 'SIGNUP_APPLE_FAILURE',

	RESET_SIGNUP_SUCCESS: 'RESET_SIGNUP_SUCCESS',

	RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

	RESET_PASSWORD_STATE: 'RESET_PASSWORD_STATE',

	VERIFY_ACCOUNT_REQUEST: 'VERIFY_ACCOUNT_REQUEST',
	VERIFY_ACCOUNT_SUCCESS: 'VERIFY_ACCOUNT_SUCCESS',
	VERIFY_ACCOUNT_FAILURE: 'VERIFY_ACCOUNT_FAILURE',

	GET_TERMS_FILE_REQUEST: 'GET_TERMS_FILE_REQUEST',
	GET_TERMS_FILE_SUCCESS: 'GET_TERMS_FILE_SUCCESS',
	GET_TERMS_FILE_FAILURE: 'GET_TERMS_FILE_FAILURE',

	ACCEPT_TERMS_REQUEST: 'ACCEPT_TERMS_REQUEST',
	ACCEPT_TERMS_SUCCESS: 'ACCEPT_TERMS_SUCCESS',
	ACCEPT_TERMS_FAILURE: 'ACCEPT_TERMS_FAILURE',

	UPGRADE_ROLE_REQUEST: 'UPGRADE_ROLE_REQUEST',
	UPGRADE_ROLE_SUCCESS: 'UPGRADE_ROLE_SUCCESS',
	UPGRADE_ROLE_FAILURE: 'UPGRADE_ROLE_FAILURE',

	DELETE_USER_ACCOUNT_REQUEST: 'DELETE_USER_ACCOUNT_REQUEST',
	DELETE_USER_ACCOUNT_SUCCESS: 'DELETE_USER_ACCOUNT_SUCCESS',
	DELETE_USER_ACCOUNT_FAILURE: 'DELETE_USER_ACCOUNT_FAILURE'
};
