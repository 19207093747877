import * as chatService from '../services/chatService';
import { chatConstants } from '../constants';

export function getAllMessages() {
	return async (dispatch) => {
		dispatch(getAllMessagesRequest());
		let result = await chatService.getAllMessages();
		if (result.OK) {
			dispatch(getAllMessagesSuccess(result.Data.responseData));
		} else {
			dispatch(getAllMessagesfailure());
		}
	};

	function getAllMessagesRequest() {
		return { type: chatConstants.GET_ALL_MESSAGES_REQUEST };
	}
	function getAllMessagesSuccess(result) {
		return { type: chatConstants.GET_ALL_MESSAGES_SUCCESS, result };
	}
	function getAllMessagesfailure() {
		return { type: chatConstants.GET_ALL_MESSAGES_FAILURE };
	}
}

export function getAllMessagesPerUserId(userId, isRead, showEmptyDialog = false, currentPage) {
	return async (dispatch) => {
		dispatch(getAllMessagesPerUserIdRequest());
		if (!isRead) {
			dispatch(markChatAsRead(userId));
		}
		let result = await chatService.getAllMessagesPerUserId(userId, currentPage);
		if (result.OK) {
			dispatch(getAllMessagesPerUserIdSuccess(result.Data.responseData, result.Data.result));
		} else {
			dispatch(getAllMessagesPerUserIdfailure());
		}
	};

	function getAllMessagesPerUserIdRequest() {
		return { type: chatConstants.GET_ALL_MESSAGES_PER_USER_REQUEST };
	}
	function getAllMessagesPerUserIdSuccess(result, numOfMessages) {
		return { type: chatConstants.GET_ALL_MESSAGES_PER_USER_SUCCESS, result, showEmptyDialog, numOfMessages };
	}
	function getAllMessagesPerUserIdfailure() {
		return { type: chatConstants.GET_ALL_MESSAGES_PER_USER_FAILURE };
	}
}

export function sendMessage(userId, message, senderId) {
	return async (dispatch) => {
		dispatch(sendMessageRequest());
		let result = await chatService.sendMessage(userId, message);
		/* if response is true, message has been sent successfully, otherwise show error */
		if (result.OK) {
			dispatch(sendMessageSuccess(result.Data, senderId, message));
		} else {
			dispatch(sendMessagefailure());
		}
	};

	function sendMessageRequest() {
		return { type: chatConstants.SEND_MESSAGE_REQUEST };
	}
	function sendMessageSuccess(result, senderId, message) {
		return {
			type: chatConstants.SEND_MESSAGE_SUCCESS,
			result,
			senderId,
			message
		};
	}
	function sendMessagefailure() {
		return { type: chatConstants.SEND_MESSAGE_FAILURE };
	}
}

export function markChatAsRead(userId) {
	return async (dispatch) => {
		dispatch(markChatAsReadRequest());
		let result = await chatService.markChatAsRead(userId);
		if (result.OK) {
			dispatch(markChatAsReadSuccess());
		} else {
			dispatch(markChatAsReadfailure());
		}
	};

	function markChatAsReadRequest() {
		return { type: chatConstants.MARK_CHAT_AS_READ_REQUEST };
	}
	function markChatAsReadSuccess() {
		return { type: chatConstants.MARK_CHAT_AS_READ_SUCCESS };
	}
	function markChatAsReadfailure() {
		return { type: chatConstants.MARK_CHAT_AS_READ_FAILURE };
	}
}

export function removeAllMessagesPerUser() {
	return async (dispatch) => {
		dispatch(removeAllMessagesPerUser());
	};
	function removeAllMessagesPerUser() {
		return { type: chatConstants.REMOVE_ALL_MESSAGES_PER_USER };
	}
}

export function setChatVisible(chatVisible) {
	return async (dispatch) => {
		dispatch(changeSetChatVisible(chatVisible));
	};
	function changeSetChatVisible(chatVisible) {
		return { type: chatConstants.CHANGE_CHAT_VISIBLE, chatVisible };
	}
}

export function setSelectedUser(selectedUser) {
	return async (dispatch) => {
		dispatch(setSelectedUserData(selectedUser));
	};
	function setSelectedUserData(selectedUser) {
		return { type: chatConstants.SET_SELECTED_USER, selectedUser };
	}
}
