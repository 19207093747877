import axios from 'axios';
import { createOKResponse, createErrorResponse } from './responses';
import config from './config';
import { getUser } from '../helpers';
import { getToken } from './authService';

export async function sendError(message, stack) {
	const user = getUser();
	try {
		const token = await getToken();
		let response;
		if (token) {
			response = await axios({
				method: 'post',
				headers: {
					Authorization: token
				},
				data: {
					hasToken: true,
					workerId: user.userAttributes.id,
					email: user.userAttributes.userEmail,
					log: message + stack
				},
				url: `${config.url}/log/save`
			});
		} else {
			response = await axios({
				method: 'post',
				data: {
					hasToken: false,
					workerId: user ? user.userAttributes.id : '',
					email: user ? user.userAttributes.userEmail : '',
					log: message + stack
				},
				url: `${config.url}/log/save`
			});
		}
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}
