import { chatConstants, globalConstants } from '../constants';
import moment from 'moment';
import { sendError } from '../services/errorService';

export function chatReducer(
	state = {
		messages: [],
		messagesPerUser: [],
		messageSent: false,
		chatMarkedAsRead: false,
		messagesLoading: true,
		messagesPerUserLoading: true,
		selectedUser: null,
		chatVisible: false,
		numberOfUserMessagesPerUser: 0
	},
	action = {}
) {
	switch (action.type) {
		//getAllMessages
		case chatConstants.GET_ALL_MESSAGES_REQUEST:
			return {
				...state,
				messagesLoading: true,
				messagesFailed: false
			};
		case chatConstants.GET_ALL_MESSAGES_SUCCESS:
			return {
				...state,
				messagesLoading: false,
				messages: action.result,
				messagesFailed: false
			};
		case chatConstants.GET_ALL_MESSAGES_FAILURE:
			return {
				...state,
				messagesLoading: false,
				messagesFailed: true
			};
		//getAllMessagesPerUserId
		case chatConstants.GET_ALL_MESSAGES_PER_USER_REQUEST:
			return {
				...state,
				messagesPerUserLoading: true,
				messagesPerUserFailed: false,
				messagesPerUserRecieved: false
			};
		case chatConstants.GET_ALL_MESSAGES_PER_USER_SUCCESS:
			let messagesPerUserLenght = action.result.length;
			return {
				...state,
				messagesPerUserLoading: false,
				messagesPerUser: [...state.messagesPerUser, ...action.result],

				messagesFailed: false,
				messagesPerUserRecieved: true,
				numberOfUserMessagesPerUser: state.numberOfUserMessagesPerUser + +messagesPerUserLenght,
				totalNumberOfUserMessagesPerUser: action.numOfMessages
			};
		case chatConstants.GET_ALL_MESSAGES_PER_USER_FAILURE:
			return {
				...state,
				messagesPerUserLoading: false,
				messagesPerUserFailed: true,
				messagesPerUserRecieved: false
			};
		case chatConstants.REMOVE_ALL_MESSAGES_PER_USER:
			return {
				...state,
				messagesPerUser: []
			};
		//sendMessage
		case chatConstants.SEND_MESSAGE_REQUEST:
			return {
				...state,
				sendMessageLoading: true,
				sendMessageFailed: false
			};
		case chatConstants.SEND_MESSAGE_SUCCESS:
			let newMessagesPerUser = [...state.messagesPerUser];
			try {
				const newMessage = {
					dateTime: moment().format(globalConstants.DATE_TIME_FORMAT),
					message: action.message,
					userId: action.senderId
				};
				const currentMessageDateScope = newMessagesPerUser.find((nm) => nm.dateTime === moment().format(globalConstants.DATE_FORMAT));
				if (currentMessageDateScope) {
					currentMessageDateScope.messages.unshift(newMessage);
				} else {
					newMessagesPerUser.unshift(newMessage);
				}
			} catch (error) {
				sendError(error.message, error.stack);
			}
			return {
				...state,
				sendMessageLoading: false,
				messageSent: true,
				messagesPerUser: newMessagesPerUser,
				sendMessageFailed: false
			};
		case chatConstants.SEND_MESSAGE_FAILURE:
			return {
				...state,
				sendMessageLoading: false,
				sendMessageFailed: true
			};
		//markChatAsRead
		case chatConstants.MARK_CHAT_AS_READ_REQUEST:
			return {
				...state,
				markChatAsReadLoading: true,
				markChatAsReadFailed: false
			};
		case chatConstants.MARK_CHAT_AS_READ_SUCCESS:
			return {
				...state,
				markChatAsReadLoading: false,
				chatMarkedAsRead: true,
				markChatAsReadFailed: false
			};
		case chatConstants.MARK_CHAT_AS_READ_FAILURE:
			return {
				...state,
				markChatAsReadLoading: false,
				markChatAsReadFailed: true
			};
		case chatConstants.CHANGE_CHAT_VISIBLE:
			return {
				...state,
				chatVisible: action.chatVisible,
				messagesPerUserRecieved: false
			};
		case chatConstants.SET_SELECTED_USER:
			return {
				...state,
				selectedUser: action.selectedUser,
				numberOfUserMessagesPerUser: 0
			};
		default:
			return state;
	}
}
