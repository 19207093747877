import { paymentHistoryConstants } from '../constants/paymentHistoryConstants';

export function paymentHistoryReducer(
	state = {
		paymentHistory: [],
		paymentHistoryLoading: true,
		numberOfPayments: 0
	},
	action = {}
) {
	switch (action.type) {
		//getPaymentHistory

		case paymentHistoryConstants.GET_PAYMENT_HISTORY_REQUEST:
			return {
				...state,
				paymentHistoryLoading: true
			};
		case paymentHistoryConstants.GET_PAYMENT_HISTORY_SUCCESS:
			return {
				...state,
				paymentHistoryLoading: false,
				paymentHistory: [...action.result.payments],
				numberOfPayments: action.numberOfPayments
			};
		case paymentHistoryConstants.GET_PAYMENT_HISTORY_FALIURE:
			return {
				...state,
				paymentHistoryLoading: false,
				paymentHistoryFailed: true
			};
		case paymentHistoryConstants.GET_PAYMENT_HISTORY_PDF_REQUEST:
			return {
				...state,
				paymentHistoryLoading: true
			};
		case paymentHistoryConstants.GET_PAYMENT_HISTORY_PDF_SUCCESS:
			return {
				...state,
				paymentHistoryLoading: false
			};
		case paymentHistoryConstants.GET_PAYMENT_HISTORY_PDF_FALIURE:
			return {
				...state,
				paymentHistoryLoading: false,
				paymentHistoryFailed: true
			};
		default:
			return state;
	}
}
