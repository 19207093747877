import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalConstants } from '../../constants';
import { refreshTokenSetup } from '../../helpers';
import { googleLogin, signUpGoogle } from '../../actions';
import { useGoogleLogin } from '@react-oauth/google';
import { withNamespaces } from 'react-i18next';
import GoogleIcon from './GoogleIcon';

const GoogleLoginComponent = React.memo(({ t, label, type, promoCode }) => {
	const dispatch = useDispatch();
	const { googleSignUpMessage } = useSelector((state) => state.authentication);

	const onSuccess = (res, a, b) => {
		if (!googleSignUpMessage) {
			if (type === globalConstants.LOGIN) {
				dispatch(googleLogin(res));
				refreshTokenSetup(res);
			} else {
				dispatch(signUpGoogle(res, promoCode));
			}
		}
	};

	const login = useGoogleLogin({
		onSuccess: onSuccess
	});

	return (
		<div
			className="google-button"
			onClick={(e) => {
				e.preventDefault();
				login();
			}}>
			<GoogleIcon />
			{t(label)}
		</div>
	);
});

export default withNamespaces()(GoogleLoginComponent);
