export const appointmentConstants = {
	GET_ALL_WORKER_APPOINTMENTS_REQUEST: 'GET_ALL_WORKER_APPOINTMENTS_REQUEST',
	GET_ALL_WORKER_APPOINTMENTS_SUCCESS: 'GET_ALL_WORKER_APPOINTMENTS_SUCCESS',
	GET_ALL_WORKER_APPOINTMENTS_FAILURE: 'GET_ALL_WORKER_APPOINTMENTS_FAILURE',

	ADD_APPOINTMENT_REQUEST: 'ADD_APPOINTMENT_REQUEST',
	ADD_APPOINTMENT_SUCCESS: 'ADD_APPOINTMENT_SUCCESS',
	ADD_APPOINTMENT_FAILURE: 'ADD_APPOINTMENT_FAILURE',

	DELETE_APPOINTMENT_REQUEST: 'DELETE_APPOINTMENT_REQUEST',
	DELETE_APPOINTMENT_SUCCESS: 'DELETE_APPOINTMENT_SUCCESS',
	DELETE_APPOINTMENT_FALIURE: 'DELETE_APPOINTMENT_FALIURE',

	EDIT_APPOINTMENT_REQUEST: 'EDIT_APPOINTMENT_REQUEST',
	EDIT_APPOINTMENT_SUCCESS: 'EDIT_APPOINTMENT_SUCCESS',
	EDIT_APPOINTMENT_FAILURE: 'EDIT_APPOINTMENT_FAILURE',

	RESET_ALL_WORKER_APPOINTMENTS: 'RESET_ALL_WORKER_APPOINTMENTS',

	ADD_BREAK_REQUEST: 'ADD_BREAK_REQUEST',
	ADD_BREAK_SUCCESS: 'ADD_BREAK_SUCCESS',
	ADD_BREAK_FAILURE: 'ADD_BREAK_FAILURE'
};
