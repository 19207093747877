import React from 'react';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { CheckmarkComponent } from './';
import { classnames } from '../../helpers';

const StepsWizzardComponent = ({ t }) => {
	const { steps } = useSelector((state) => state.changeState);

	return (
		<div className="steps-wizzard">
			{steps.map((step, stepIndex) => {
				const stepInnerClassName = classnames('steps-wizzard__inner', step.finished && 'steps-wizzard__inner--finished');
				const stepNumberClassName = classnames('steps-wizzard__step-number', step.finished && 'steps-wizzard__step-number--finished');
				return (
					<div className={stepInnerClassName} key={stepIndex}>
						<div className={stepNumberClassName}>{step.finished ? <CheckmarkComponent /> : <span className="info-icon"></span>}</div>
						{step.component}
					</div>
				);
			})}
		</div>
	);
};

export default withNamespaces()(StepsWizzardComponent);
