import * as appointmentService from '../services/appointmentService';
import { appointmentConstants } from '../constants/appointmentConstants';
import { sendError } from '../services/errorService';

export function getAllWorkerAppointments(workerId, companyId, date, currentPage, timeStatus, status, page, sort, itemsPerPage) {
	return async (dispatch) => {
		dispatch(getAllWorkerAppointmentsRequest());
		let result = await appointmentService.getAllWorkerAppointments(
			workerId,
			companyId,
			date,
			currentPage,
			timeStatus,
			status,
			page,
			sort,
			itemsPerPage
		);
		if (result.OK) {
			dispatch(getAllWorkerAppointmentsSuccess(result.Data, result.numbersOfData));
		} else {
			dispatch(getAllWorkerAppointmentsFailure());
		}
	};

	function getAllWorkerAppointmentsRequest() {
		return { type: appointmentConstants.GET_ALL_WORKER_APPOINTMENTS_REQUEST };
	}
	function getAllWorkerAppointmentsSuccess(result, numberOfWorkerAppointments) {
		return {
			type: appointmentConstants.GET_ALL_WORKER_APPOINTMENTS_SUCCESS,
			result,
			numberOfWorkerAppointments
		};
	}
	function getAllWorkerAppointmentsFailure() {
		return { type: appointmentConstants.GET_ALL_WORKER_APPOINTMENTS_FAILURE };
	}
}

export function resetAllWorkerAppointments() {
	return {
		type: appointmentConstants.RESET_ALL_WORKER_APPOINTMENTS
	};
}

export function addAppointment(data) {
	return async (dispatch) => {
		dispatch(addAppointmentRequest());
		let result = await appointmentService.addAppointment(data);
		if (result.OK) {
			dispatch(addAppointmentSuccess(result.Data));
		} else {
			try {
				dispatch(addAppointmentsFailure(result.ErrorText));
			} catch (error) {
				sendError('AppointmentActions - addAppointment()', error);
			}
		}
	};

	function addAppointmentRequest() {
		return { type: appointmentConstants.ADD_APPOINTMENT_REQUEST };
	}
	function addAppointmentSuccess(result) {
		return {
			type: appointmentConstants.ADD_APPOINTMENT_SUCCESS,
			result
		};
	}
	function addAppointmentsFailure(message) {
		return { type: appointmentConstants.ADD_APPOINTMENT_FAILURE, message };
	}
}

export function deleteAppointment(appointmentId) {
	return async (dispatch) => {
		dispatch(deleteAppointmentRequest());
		let result = await appointmentService.deleteAppointment(appointmentId);

		if (result.OK) {
			dispatch(deleteAppointmentSuccess());
		} else {
			dispatch(deleteAppointmentFaliure(result.ErrorText));
		}
	};

	function deleteAppointmentRequest() {
		return { type: appointmentConstants.DELETE_APPOINTMENT_REQUEST };
	}

	function deleteAppointmentSuccess() {
		return { type: appointmentConstants.DELETE_APPOINTMENT_SUCCESS };
	}

	function deleteAppointmentFaliure(message) {
		return { type: appointmentConstants.DELETE_APPOINTMENT_FALIURE, message };
	}
}

export function editAppointment(data) {
	return async (dispatch) => {
		dispatch(editAppointmentRequest());
		let result;
		if (data.appointmentStatus === 'approved') {
			result = await appointmentService.approveAppointment(data.appointmentId);
		} else if (data.appointmentStatus === 'declined') {
			result = await appointmentService.declineAppointment(data.appointmentId);
		} else {
			result = await appointmentService.editAppointment(data);
		}
		if (result.OK) {
			dispatch(editAppointmentSuccess(result.Data.appointment[0]));
		} else {
			dispatch(editAppointmentFaliure(result.ErrorText));
		}
	};

	function editAppointmentRequest() {
		return { type: appointmentConstants.EDIT_APPOINTMENT_REQUEST };
	}
	function editAppointmentSuccess(result) {
		return {
			type: appointmentConstants.EDIT_APPOINTMENT_SUCCESS,
			result
		};
	}
	function editAppointmentFaliure(message) {
		return { type: appointmentConstants.EDIT_APPOINTMENT_FAILURE, message };
	}
}

export function addBreak(workerId, companyId, breakDay, breakStart, breakEnd) {
	return async (dispatch) => {
		dispatch(addBreakRequest());

		let result = await appointmentService.addBreak(workerId, companyId, breakDay, breakStart, breakEnd);

		if (result.OK) {
			dispatch(addBreakSuccess(result.Data.appointment));
		} else {
			dispatch(addBreakFailure(result.Data.message));
		}
	};

	function addBreakRequest() {
		return { type: appointmentConstants.ADD_BREAK_REQUEST };
	}

	function addBreakSuccess(result) {
		return { type: appointmentConstants.ADD_BREAK_SUCCESS, result };
	}

	function addBreakFailure(message) {
		return { type: appointmentConstants.ADD_BREAK_FAILURE, message };
	}
}
