import {
	faBuilding,
	faCalendarAlt,
	faUsers,
	faAirFreshener, // Zamena za 'cib-apache-airflow'
	faComments, // Zamena za 'cil-notes'
	faChartLine,
	faCreditCard,
	faBookOpen,
	faQuestionCircle,
	faDollarSign,
	faBold,
	faAddressBook,
	faHeadphones,
	faListAlt
} from '@fortawesome/free-solid-svg-icons';
import { sendError } from '../services/errorService';

export const getNavigations = (roleName) => {
	try {
		let nav = [];

		if (roleName === 'headAdmin') {
			nav.push(
				{
					name: 'label.categories',
					to: '/categories',
					icon: faListAlt
				},
				{
					name: 'label.stores',
					to: '/company',
					icon: faBuilding
				},
				{
					name: 'label.timesheet',
					to: '/timesheet',
					icon: faCalendarAlt
				},
				{
					name: 'label.employees',
					to: '/worker',
					icon: faUsers
				},
				{
					name: 'label.services',
					to: '/services',
					icon: faAirFreshener
				},
				{
					name: 'label.reviews',
					to: '/reviews',
					icon: faComments
				},
				{
					name: 'label.classifications',
					to: '/classifications',
					icon: faListAlt
				},
				{
					name: 'label.statistics',
					to: '/statistics',
					icon: faChartLine
				},
				{
					name: 'label.payment_history',
					to: '/payment-history',
					icon: faCreditCard
				},
				{
					name: 'label.appointments',
					to: '/appointments',
					icon: faBookOpen
				},
				{
					name: 'label.dashboard',
					to: '/dashboard',
					icon: faAddressBook
				},
				{
					name: 'label.help',
					to: '/help',
					icon: faQuestionCircle
				},
				{
					name: 'label.subscription_plan',
					to: '/subscription-plan',
					icon: faDollarSign
				},
				{
					name: 'label.promo_codes',
					to: '/promo-codes',
					icon: faBold
				}
			);
		}

		if (roleName === 'companyAdmin') {
			nav.push(
				{
					name: 'label.appointments',
					to: '/appointments',
					icon: faBookOpen
				},
				{
					name: 'label.stores',
					to: '/company',
					icon: faBuilding
				},
				{
					name: 'label.timesheet',
					to: '/timesheet',
					icon: faCalendarAlt
				},
				{
					name: 'label.employees',
					to: '/worker',
					icon: faUsers
				},
				{
					name: 'label.services',
					to: '/services',
					icon: faAirFreshener
				},
				{
					name: 'label.reviews',
					to: '/reviews',
					icon: faComments
				},
				{
					name: 'label.statistics',
					to: '/statistics',
					icon: faChartLine
				},
				{
					name: 'label.payment_history',
					to: '/payment-history',
					icon: faCreditCard
				},
				{
					name: 'label.profile',
					to: '/profile',
					icon: faAddressBook
				},
				{
					name: 'label.help',
					to: '/help',
					icon: faQuestionCircle
				},
				{
					name: 'label.contact_support',
					to: '/contact-support',
					icon: faHeadphones
				},
				{
					name: 'label.subscription_plan',
					to: '/subscription-plan',
					icon: faDollarSign
				},
				{
					name: 'label.promo_codes',
					to: '/promo-codes',
					icon: faBold
				}
			);
		}

		if (roleName === 'worker') {
			nav.push(
				{
					name: 'label.appointments',
					to: '/appointments',
					icon: faBookOpen
				},
				{
					name: 'label.timesheet',
					to: '/timesheet',
					icon: faCalendarAlt
				},
				{
					name: 'label.services',
					to: '/services',
					icon: faAirFreshener
				},
				{
					name: 'label.reviews',
					to: '/reviews',
					icon: faComments
				},
				{
					name: 'label.payment_history',
					to: '/payment-history',
					icon: faCreditCard
				},
				{
					name: 'label.profile',
					to: '/profile',
					icon: faAddressBook
				},
				{
					name: 'label.help',
					to: '/help',
					icon: faQuestionCircle
				},
				{
					name: 'label.contact_support',
					to: '/contact-support',
					icon: faHeadphones
				}
			);
		}

		return nav;
	} catch (error) {
		sendError('getNavigations() - ', error.stack);
		return [];
	}
};
