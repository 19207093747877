export const serviceConstants = {
	GET_SERVICE_CATEGORIES_REQUEST: 'GET_SERVICE_CATEGORIES_REQUEST',
	GET_SERVICE_CATEGORIES_SUCCESS: 'GET_SERVICE_CATEGORIES_SUCCESS',
	GET_SERVICE_CATEGORIES_FAILURE: 'GET_SERVICE_CATEGORIES_FAILURE',

	DELETE_SERVICE_CATEGORY_REQUEST: 'DELETE_SERVICE_CATEGORY_REQUEST',
	DELETE_SERVICE_CATEGORY_SUCCESS: 'DELETE_SERVICE_CATEGORY_SUCCESS',
	DELETE_SERVICE_CATEGORY_FAILURE: 'DELETE_SERVICE_CATEGORY_FAILURE',

	ADD_SERVICE_CATEGORY_REQUEST: 'ADD_SERVICE_CATEGORY_REQUEST',
	ADD_SERVICE_CATEGORY_SUCCESS: 'ADD_SERVICE_CATEGORY_SUCCESS',
	ADD_SERVICE_CATEGORY_FAILURE: 'ADD_SERVICE_CATEGORY_FAILURE',

	EDIT_SERVICE_CATEGORY_REQUEST: 'EDIT_SERVICE_CATEGORY_REQUEST',
	EDIT_SERVICE_CATEGORY_SUCCESS: 'EDIT_SERVICE_CATEGORY_SUCCESS',
	EDIT_SERVICE_CATEGORY_FAILURE: 'EDIT_SERVICE_CATEGORY_FAILURE',

	EDIT_SERVICE_CATEGORY_ORDER_REQUEST: 'EDIT_SERVICE_CATEGORY_ORDER_REQUEST',
	EDIT_SERVICE_CATEGORY_ORDER_SUCCESS: 'EDIT_SERVICE_CATEGORY_ORDER_SUCCESS',
	EDIT_SERVICE_CATEGORY_ORDER_FAILURE: 'EDIT_SERVICE_CATEGORY_ORDER_FAILURE',

	ADD_SERVICE_REQUEST: 'ADD_SERVICE_REQUEST',
	ADD_SERVICE_SUCCESS: 'ADD_SERVICE_SUCCESS',
	ADD_SERVICE_FAILURE: 'ADD_SERVICE_FAILURE',

	EDIT_SERVICE_REQUEST: 'EDIT_SERVICE_REQUEST',
	EDIT_SERVICE_SUCCESS: 'EDIT_SERVICE_SUCCESS',
	EDIT_SERVICE_FAILURE: 'EDIT_SERVICE_FAILURE',

	EDIT_SERVICE_ORDER_REQUEST: 'EDIT_SERVICE_ORDER_REQUEST',
	EDIT_SERVICE_ORDER_SUCCESS: 'EDIT_SERVICE_ORDER_SUCCESS',
	EDIT_SERVICE_ORDER_FAILURE: 'EDIT_SERVICE_ORDER_FAILURE',

	DELETE_SERVICE_REQUEST: 'DELETE_SERVICE_REQUEST',
	DELETE_SERVICE_SUCCESS: 'DELETE_SERVICE_SUCCESS',
	DELETE_SERVICE_FAILURE: 'DELETE_SERVICE_FAILURE'
};
