import { companyConstants } from '../constants/companyConstants';
import * as companyService from '../services/companyService';
import { updateUserAttributes } from '../actions';
import { isArray } from 'underscore';

export function getCompanies(categoryId) {
	return async (dispatch) => {
		dispatch(getCompaniesRequest());

		let result = await companyService.getCompanies(categoryId);

		if (result.OK) {
			dispatch(getCompaniesSuccess(result.Data));
		} else {
			dispatch(getCompaniesFailure());
		}
	};

	function getCompaniesRequest() {
		return {
			type: companyConstants.GET_COMPANIES_REQUEST
		};
	}

	function getCompaniesSuccess(result) {
		return {
			type: companyConstants.GET_COMPANIES_SUCCESS,
			result
		};
	}

	function getCompaniesFailure() {
		return {
			type: companyConstants.GET_COMPANIES_FAILURE
		};
	}
}

export function getCompany(companyId) {
	return async (dispatch) => {
		dispatch(getCompanyRequest());

		let result = await companyService.getCompany(companyId);

		if (result.OK) {
			dispatch(getCompanySuccess(result.Data));
		} else {
			dispatch(getCompanyFailure());
		}
	};

	function getCompanyRequest() {
		return {
			type: companyConstants.GET_COMPANY_REQUEST
		};
	}

	function getCompanySuccess(result) {
		return {
			type: companyConstants.GET_COMPANY_SUCCESS,
			result
		};
	}

	function getCompanyFailure() {
		return {
			type: companyConstants.GET_COMPANY_FAILURE
		};
	}
}

export function getCompaniesByUser(userId) {
	return async (dispatch) => {
		dispatch(getCompanyRequest());

		let result = await companyService.getCompaniesByUser(userId);

		if (result.OK) {
			dispatch(getCompanySuccess(result.Data));
		} else {
			dispatch(getCompanyFailure());
		}
	};

	function getCompanyRequest() {
		return {
			type: companyConstants.GET_COMPANIES_BY_USER_REQUEST
		};
	}

	function getCompanySuccess(result) {
		return {
			type: companyConstants.GET_COMPANIES_BY_USER_SUCCESS,
			result
		};
	}

	function getCompanyFailure() {
		return {
			type: companyConstants.GET_COMPANIES_BY_USER_FAILURE
		};
	}
}

export function addCompany(
	categoryId,
	companyName,
	companyShortDesc,
	companyLongDesc,
	newImages,
	mondayTimeFrom,
	mondayTimeTo,
	tuesdayTimeFrom,
	tuesdayTimeTo,
	wednesdayTimeFrom,
	wednesdayTimeTo,
	thursdayTimeFrom,
	thursdayTimeTo,
	fridayTimeFrom,
	fridayTimeTo,
	saturdayTimeFrom,
	saturdayTimeTo,
	sundayTimeFrom,
	sundayTimeTo,
	address,
	area,
	city,
	latitude,
	longitude,
	companyPhoneNumber,
	companyUrl,
	legalEntity,
	payWithCard,
	payWithCash,
	companyAccount,
	companyLogo,
	roleName,
	serviceBreak,
	companyInstagram,
	companyFacebook,
	companyFake,
	locationOnline,
	singleCompany
) {
	return async (dispatch) => {
		dispatch(addCompanyRequest());

		let result = await companyService.addCompany(
			categoryId,
			companyName,
			companyShortDesc,
			companyLongDesc,
			newImages,
			mondayTimeFrom,
			mondayTimeTo,
			tuesdayTimeFrom,
			tuesdayTimeTo,
			wednesdayTimeFrom,
			wednesdayTimeTo,
			thursdayTimeFrom,
			thursdayTimeTo,
			fridayTimeFrom,
			fridayTimeTo,
			saturdayTimeFrom,
			saturdayTimeTo,
			sundayTimeFrom,
			sundayTimeTo,
			address,
			area,
			city,
			latitude,
			longitude,
			companyPhoneNumber,
			companyUrl,
			legalEntity,
			payWithCard,
			payWithCash,
			companyAccount,
			companyLogo,
			serviceBreak,
			companyInstagram,
			companyFacebook,
			companyFake,
			locationOnline
		);

		if (result.OK) {
			dispatch(addCompanySuccess(result.Data));
			if (roleName === 'companyAdmin' && isArray(result.Data)) {
				dispatch(updateUserAttributes(result.Data, false, true));
			}
			// TREBA BITI PREBACENO NA BACKEND - odradjeno ovako u slucaju da je dodata kompanija, pa zatim obrisana, pa dodata nova objekat u kome radite je false. Ovo ce resiti da bude true
			if (singleCompany && result.Data?.length > 0 && result.Data[0].userStatus === 'inactive') {
				dispatch(changeWorkerActiveCompany(result.Data[0].id));
			}
		} else {
			dispatch(addCompanyFailure(result.ErrorText));
		}
	};

	function addCompanyRequest() {
		return {
			type: companyConstants.ADD_COMPANY_REQUEST
		};
	}

	function addCompanySuccess(result) {
		return {
			type: companyConstants.ADD_COMPANY_SUCCESS,
			result
		};
	}

	function addCompanyFailure(message) {
		return {
			type: companyConstants.ADD_COMPANY_FAILURE,
			message
		};
	}
}

export function editCompany(
	companyId,
	categoryId,
	companyName,
	companyShortDesc,
	companyLongDesc,
	newImages,
	deleteImages,
	mondayTimeFrom,
	mondayTimeTo,
	tuesdayTimeFrom,
	tuesdayTimeTo,
	wednesdayTimeFrom,
	wednesdayTimeTo,
	thursdayTimeFrom,
	thursdayTimeTo,
	fridayTimeFrom,
	fridayTimeTo,
	saturdayTimeFrom,
	saturdayTimeTo,
	sundayTimeFrom,
	sundayTimeTo,
	address,
	area,
	city,
	latitude,
	longitude,
	companyPhoneNumber,
	companyUrl,
	legalEntity,
	payWithCard,
	payWithCash,
	companyAccount,
	companyLogo,
	serviceBreak,
	companyInstagram,
	companyFacebook,
	companyFake,
	locationOnline
) {
	return async (dispatch) => {
		dispatch(request());

		let result = await companyService.editCompany(
			companyId,
			categoryId,
			companyName,
			companyShortDesc,
			companyLongDesc,
			newImages,
			deleteImages,
			mondayTimeFrom,
			mondayTimeTo,
			tuesdayTimeFrom,
			tuesdayTimeTo,
			wednesdayTimeFrom,
			wednesdayTimeTo,
			thursdayTimeFrom,
			thursdayTimeTo,
			fridayTimeFrom,
			fridayTimeTo,
			saturdayTimeFrom,
			saturdayTimeTo,
			sundayTimeFrom,
			sundayTimeTo,
			address,
			area,
			city,
			latitude,
			longitude,
			companyPhoneNumber,
			companyUrl,
			legalEntity,
			payWithCard,
			payWithCash,
			companyAccount,
			companyLogo,
			serviceBreak,
			companyInstagram,
			companyFacebook,
			companyFake,
			locationOnline
		);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure(result.ErrorText));
		}
	};

	function request() {
		return {
			type: companyConstants.EDIT_COMPANY_REQUEST
		};
	}

	function success(result) {
		return {
			type: companyConstants.EDIT_COMPANY_SUCCESS,
			result
		};
	}

	function failure(message) {
		return {
			type: companyConstants.EDIT_COMPANY_FAILURE,
			message
		};
	}
}

export function setCompanies(companies) {
	return {
		type: companyConstants.SET_COMPANIES,
		result: companies
	};
}

export function resetCompanyAddEditState() {
	return {
		type: companyConstants.RESET_COMPANY_ADD_EDIT_STATE
	};
}

export function deleteCompany(companyId) {
	return async (dispatch) => {
		dispatch(deleteCompanyRequest());

		let result = await companyService.deleteCompany(companyId);

		if (result.OK) {
			dispatch(deleteCompanySuccess(result.Data));
			dispatch(updateUserAttributes(result.Data, false, true));
		} else {
			dispatch(deleteCompanyFailure(result.ErrorText));
		}
	};

	function deleteCompanyRequest() {
		return {
			type: companyConstants.DELETE_COMPANY_REQUEST
		};
	}

	function deleteCompanySuccess(result) {
		return {
			type: companyConstants.DELETE_COMPANY_SUCCESS,
			result
		};
	}

	function deleteCompanyFailure(message) {
		return {
			type: companyConstants.DELETE_COMPANY_FAILURE,
			message
		};
	}
}

export function changeWorkerActiveCompany(companyId) {
	return async (dispatch) => {
		dispatch(request());

		let result = await companyService.changeWorkerActiveCompany(companyId);
		if (result.OK) {
			dispatch(success(result.Data));
			dispatch(updateUserAttributes(result.Data, false, true));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: companyConstants.CHANGE_WORKER_ACTIVE_COMPANY_REQUEST };
	}
	function success(result) {
		return { type: companyConstants.CHANGE_WORKER_ACTIVE_COMPANY_SUCCESS, result };
	}
	function failure() {
		return { type: companyConstants.CHANGE_WORKER_ACTIVE_COMPANY_FAILURE };
	}
}
