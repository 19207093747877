export const chatConstants = {
	GET_ALL_MESSAGES_REQUEST: 'GET_ALL_MESSAGES_REQUEST',
	GET_ALL_MESSAGES_SUCCESS: 'GET_ALL_MESSAGES_SUCCESS',
	GET_ALL_MESSAGES_FAILURE: 'GET_ALL_MESSAGES_FAILURE',

	GET_ALL_MESSAGES_PER_USER_REQUEST: 'GET_ALL_MESSAGES_PER_USER_REQUEST',
	GET_ALL_MESSAGES_PER_USER_SUCCESS: 'GET_ALL_MESSAGES_PER_USER_SUCCESS',
	GET_ALL_MESSAGES_PER_USER_FAILURE: 'GET_ALL_MESSAGES_PER_USER_FAILURE',

	SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
	SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
	SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',

	MARK_CHAT_AS_READ_REQUEST: 'MARK_CHAT_AS_READ_REQUEST',
	MARK_CHAT_AS_READ_SUCCESS: 'MARK_CHAT_AS_READ_SUCCESS',
	MARK_CHAT_AS_READ_FAILURE: 'MARK_CHAT_AS_READ_FAILURE',

	REMOVE_ALL_MESSAGES_PER_USER: 'REMOVE_ALL_MESSAGES_PER_USER',
	CHANGE_CHAT_VISIBLE: 'CHANGE_CHAT_VISIBLE',
	SET_SELECTED_USER: 'SET_SELECTED_USER'
};
