import { appointmentConstants } from '../constants/appointmentConstants';

export function appointmentReducer(
	state = {
		workerAppointments: [],
		workerAppointmentsLoading: true,
		numberOfWorkerAppointments: 0,
		appointmentsResponses: [],
		appointment: {},
		addBreak: false
	},
	action = {}
) {
	switch (action.type) {
		//getAllWorkerAppointments

		case appointmentConstants.GET_ALL_WORKER_APPOINTMENTS_REQUEST:
			return {
				...state,
				workerAppointmentsLoading: true,
				workerAppointmentsFailed: false,
				resetAllWorkerAppointments: false
			};
		case appointmentConstants.GET_ALL_WORKER_APPOINTMENTS_SUCCESS:
			return {
				...state,
				workerAppointmentsLoading: false,
				workerAppointments: [...action.result.appointments],
				numberOfWorkerAppointments: action.numberOfWorkerAppointments
			};
		case appointmentConstants.GET_ALL_WORKER_APPOINTMENTS_FAILURE:
			return {
				...state,
				workerAppointmentsLoading: false,
				workerAppointmentsFailed: true
			};
		//reset worker appointments
		case appointmentConstants.RESET_ALL_WORKER_APPOINTMENTS:
			return {
				...state,
				workerAppointments: []
			};

		// addAppointment
		case appointmentConstants.ADD_APPOINTMENT_REQUEST:
			return {
				...state,
				addAppointmentLoading: true,
				addedAppointment: false,
				addAppointmentFailedMessage: ''
			};
		case appointmentConstants.ADD_APPOINTMENT_SUCCESS:
			return {
				...state,
				addAppointmentLoading: false,
				addedAppointment: true,
				addAppointmentFailedMessage: '',
				workerAppointments: action.result.appointment
					? [...action.result.appointment, ...state.workerAppointments]
					: [...action.result.appointments, ...state.workerAppointments]
			};
		case appointmentConstants.ADD_APPOINTMENT_FAILURE:
			return {
				...state,
				addAppointmentLoading: false,
				addAppointmentFailedMessage: action.message,
				addedAppointment: false,
				appointmentsResponses: [...state.appointmentsResponses, false]
			};
		// edit appointments
		case appointmentConstants.EDIT_APPOINTMENT_REQUEST:
			return {
				...state,
				editAppointmentLoading: true,
				editAppointment: false,
				editAppointmentFailedMessage: ''
			};
		case appointmentConstants.EDIT_APPOINTMENT_SUCCESS:
			let editedAppointments = [];
			editedAppointments = [...state.workerAppointments].map((app) => {
				return app.id === action.result.id ? action.result : app;
			});
			return {
				...state,
				editAppointmentLoading: false,
				editAppointment: true,
				workerAppointments: editedAppointments
			};
		case appointmentConstants.EDIT_APPOINTMENT_FAILURE:
			return {
				...state,
				editAppointmentLoading: false,
				editAppointment: false,
				editAppointmentFailedMessage: action.message
			};
		// delete appointments
		case appointmentConstants.DELETE_APPOINTMENT_REQUEST:
			return {
				...state,
				deleteAppointmentFailedMessage: '',
				deletedAppointment: false
			};
		case appointmentConstants.DELETE_APPOINTMENT_SUCCESS:
			return {
				...state,
				deletedAppointment: true
			};
		case appointmentConstants.DELETE_APPOINTMENT_FALIURE:
			return {
				...state,
				deletedAppointment: false,
				deleteAppointmentFailedMessage: action.message
			};
		//addBreak
		case appointmentConstants.ADD_BREAK_REQUEST:
			return {
				...state,
				addBreakFailed: false,
				addBreak: false
			};
		case appointmentConstants.ADD_BREAK_SUCCESS:
			return {
				...state,
				addBreakFailed: false,
				addBreak: true,
				breaks: action.result
			};
		case appointmentConstants.ADD_BREAK_FAILURE:
			return {
				...state,
				addBreakFailed: true,
				addBreak: false,
				addBreakErrorMessage: action.message
			};
		default:
			return state;
	}
}
