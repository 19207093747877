import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Form } from 'react-bootstrap';
import Geocode from 'react-geocode';
import { GoogleMap, MarkerF, Autocomplete, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
	height: '400px',
	width: '100%'
};
const libraries = ['places'];

// port 3002
Geocode.setApiKey('AIzaSyBOk9nmEC_0x7w0NsghQ9hbaR6mOGiBEJQ');

const GoogleMapComponent = React.memo(({ t, center, onLocationChange, zoom }) => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyBOk9nmEC_0x7w0NsghQ9hbaR6mOGiBEJQ',
		libraries: useMemo(() => libraries, []) // Pass a memoized array
	});

	const { userAttributes } = useSelector((state) => state.authentication);
	const { role } = userAttributes;
	const isHeadAdmin = role && role.roleName === 'headAdmin';
	const isCompanyAdmin = role && role.roleName === 'companyAdmin';

	const [autocomplete, setAutocomplete] = useState();
	const [inputs, setInputs] = useState({
		address: center.address || '',
		city: center.city || '',
		area: center.area || '',
		state: center.state || '',
		mapPosition: {
			lat: center.lat,
			lng: center.lng
		},
		markerPosition: {
			lat: +center.lat,
			lng: +center.lng
		}
	});

	const onAutocompleteLoad = (autocomplete) => {
		setAutocomplete(autocomplete);
	};

	const { address, area, markerPosition } = inputs;

	/**
	 * Get the city and set the city input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	const getCity = (addressArray) => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};
	/**
	 * Get the area and set the area input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	const getArea = (addressArray) => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};
	/**
	 * Get the address and set the address input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	const getState = (addressArray) => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};
	/**
	 * And function for city,state and address input
	 * @param event
	 */
	const onChange = (event) => {
		setInputs((inputs) => ({
			...inputs,
			[event.target.name]: event.target.value
		}));
	};
	/**
	 * When the user types an address in the search box
	 * @param place
	 */
	const onPlaceChanged = () => {
		try {
			const place = autocomplete.getPlace();
			const address = place.formatted_address,
				addressArray = place.address_components;
			if (addressArray) {
				const city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray),
					latValue = place.geometry.location.lat(),
					lngValue = place.geometry.location.lng();
				// Set these values in the state.
				setInputs((inputs) => ({
					...inputs,
					address: address ? address : '',
					area: area ? area : '',
					city: city ? city : '',
					state: state ? state : '',
					markerPosition: {
						lat: latValue,
						lng: lngValue
					},
					mapPosition: {
						lat: latValue,
						lng: lngValue
					}
				}));
				onLocationChange(address, area, city, latValue, lngValue);
			}
		} catch (error) {}
	};
	/**
	 * When the marker is dragged you get the lat and long using the functions available from event object.
	 * Use geocode to get the address, city, area and state from the lat and lng positions.
	 * And then set those values in the state.
	 *
	 * @param event
	 */
	const onPositionChanged = (event) => {
		if (event) {
			let newLat = event.latLng.lat(),
				newLng = event.latLng.lng();

			Geocode.fromLatLng(newLat, newLng).then(
				(response) => {
					const address = response.results[0].formatted_address,
						addressArray = response.results[0].address_components,
						city = getCity(addressArray),
						area = getArea(addressArray),
						state = getState(addressArray);
					setInputs((inputs) => ({
						...inputs,
						address: address ? address : '',
						area: area ? area : '',
						city: city ? city : '',
						state: state ? state : ''
					}));
					onLocationChange(address, area, city, newLat, newLng);
				},
				(error) => {
					console.error(error);
				}
			);
		}
	};

	const handleOnDragEnd = (event) => {
		onPositionChanged(event);
	};

	const onMarkerLoad = (marker) => {};

	return (
		<>
			{isLoaded && (
				<>
					<Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
						<div className="form-group">
							<Form.Control
								type="text"
								maxLength={150}
								placeholder={t('label.enter_store_location')}
								readOnly={!isHeadAdmin && !isCompanyAdmin}
							/>
						</div>
					</Autocomplete>
					<GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
						<MarkerF
							draggable={true}
							onLoad={onMarkerLoad}
							position={markerPosition}
							onPositionChanged={onPositionChanged}
							onDragEnd={handleOnDragEnd}
						/>
					</GoogleMap>
				</>
			)}
			<div className="form-group company-page__city">
				<Form.Label htmlFor="city">{`${t('label.city')} - ${t('label.automatically_filled_field')}`}</Form.Label>
				<Form.Control type="text" name="area" value={area} readOnly={true} onChange={onChange} />
			</div>
			<div className="form-group">
				<Form.Label htmlFor="address">{`${t('label.address')} - ${t('label.automatically_filled_field')}`}</Form.Label>
				<Form.Control type="text" name="address" value={address} readOnly={true} onChange={onChange} required maxLength={150} />
				<Form.Control.Feedback type="invalid" name="address_error">
					{t('error.required')}
				</Form.Control.Feedback>
			</div>
		</>
	);
});

export default withNamespaces()(GoogleMapComponent);
