import { categoryConstants } from '../constants/categoryConstants';
import * as categoryService from '../services/categoryService';

export function getCategories() {
	return async (dispatch) => {
		dispatch(getCategoriesRequest());
		let result = await categoryService.getCategories();

		if (result.OK) {
			let modifiedCategories = result.Data.map((category) => {
				return {
					value: category.id,
					label: category.name,
					id: category.id,
					name: category.name,
					iconName: category.iconName
				};
			});
			const othersIndex = modifiedCategories.findIndex((x) => x.name === 'Ostalo' || x.name === 'Others');

			modifiedCategories.push(modifiedCategories.splice(othersIndex, 1)[0]);
			dispatch(getCategoriesSuccess(modifiedCategories));
		} else {
			dispatch(getCategoriesFailure());
		}
	};

	function getCategoriesRequest() {
		return {
			type: categoryConstants.GET_CATEGORIES_REQUEST
		};
	}

	function getCategoriesSuccess(result) {
		return {
			type: categoryConstants.GET_CATEGORIES_SUCCESS,
			result
		};
	}

	function getCategoriesFailure() {
		return { type: categoryConstants.GET_CATEGORIES_FAILURE };
	}
}

export function deleteCategory(categoryId) {
	return async (dispatch) => {
		dispatch(deleteCategoryRequest());

		let result = await categoryService.deleteCategory(categoryId);

		if (result.OK) {
			dispatch(deleteCategorySuccess());
		} else {
			dispatch(deleteCategoryFaliure(result.ErrorText));
		}
	};

	function deleteCategoryRequest() {
		return {
			type: categoryConstants.DELETE_CATEGORY_REQUEST
		};
	}

	function deleteCategorySuccess() {
		return {
			type: categoryConstants.DELETE_CATEGORY_SUCCESS
		};
	}

	function deleteCategoryFaliure(message) {
		return {
			type: categoryConstants.DELETE_CATEGORY_FAILURE,
			message
		};
	}
}

export function addCategory(categoryName, iconName) {
	return async (dispatch) => {
		dispatch(addCategoryRequest());

		let result = await categoryService.addCategory(categoryName, iconName);

		if (result.OK) {
			dispatch(addCategorySuccess(result.Data));
		} else {
			dispatch(addCategoryFaliure(result.ErrorText));
		}
	};

	function addCategoryRequest() {
		return {
			type: categoryConstants.ADD_CATEGORY_REQUEST
		};
	}

	function addCategorySuccess(result) {
		return {
			type: categoryConstants.ADD_CATEGORY_SUCCESS,
			result
		};
	}

	function addCategoryFaliure(message) {
		return {
			type: categoryConstants.ADD_CATEGORY_FAILURE,
			message
		};
	}
}

export function editCategory(id, categoryName, iconName) {
	return async (disptach) => {
		disptach(editCategoryRequest());

		let result = await categoryService.editCategory(id, categoryName, iconName);

		if (result.OK) {
			disptach(editCategorySuccess(result.Data));
		} else {
			disptach(editCategoryFaliure(result.ErrorText));
		}
	};

	function editCategoryRequest() {
		return {
			type: categoryConstants.EDIT_CATEGORY_REQUEST
		};
	}

	function editCategorySuccess(result) {
		return {
			type: categoryConstants.EDIT_CATEGORY_SUCCESS,
			result
		};
	}

	function editCategoryFaliure(message) {
		return {
			type: categoryConstants.EDIT_CATEGORY_FAILURE,
			message
		};
	}
}
