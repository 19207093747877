export const workerConstants = {
	REGISTER_WORKER_REQUEST: 'REGISTER_WORKER_REQUEST',
	REGISTER_WORKER_SUCCESS: 'REGISTER_WORKER_SUCCESS',
	REGISTER_WORKER_FAILURE: 'REGISTER_WORKER_FAILURE',

	SET_WORKER_SUCCESS: 'SET_WORKER_SUCCESS',
	SET_WORKER_FAILURE: 'SET_WORKER_FAILURE',

	REGISTER_WORKER_RESET_STATE: 'REGISTER_WORKER_RESET_STATE',

	GET_WORKER_REQUEST: 'GET_WORKER_REQUEST',
	GET_WORKER_SUCCESS: 'GET_WORKER_SUCCESS',
	GET_WORKER_FAILURE: 'GET_WORKER_FAILURE',

	SAVE_NOFITICATION_PARAM_REQUEST: 'SAVE_NOFITICATION_PARAM_REQUEST',
	SAVE_NOFITICATION_PARAM_SUCCESS: 'SAVE_NOFITICATION_PARAM_SUCCESS',
	SAVE_NOFITICATION_PARAM_FAILURE: 'SAVE_NOFITICATION_PARAM_FAILURE',

	UPDATE_USER_ATTRIBUTES: 'UPDATE_USER_ATTRIBUTES',

	DELETE_WORKER_REQUEST: 'DELETE_WORKER_REQUEST',
	DELETE_WORKER_SUCCESS: 'DELETE_WORKER_SUCCESS',
	DELETE_WORKER_FAILURE: 'DELETE_WORKER_FAILURE',

	ADD_WORKER_REQUEST: 'ADD_WORKER_REQUEST',
	ADD_WORKER_SUCCESS: 'ADD_WORKER_SUCCESS',
	ADD_WORKER_FAILURE: 'ADD_WORKER_FAILURE',

	EDIT_WORKER_REQUEST: 'EDIT_WORKER_REQUEST',
	EDIT_WORKER_SUCCESS: 'EDIT_WORKER_SUCCESS',
	EDIT_WORKER_FAILURE: 'EDIT_WORKER_FAILURE',

	GET_WORKERS_REQUEST: 'GET_WORKERS_REQUEST',
	GET_WORKERS_SUCCESS: 'GET_WORKERS_SUCCESS',
	GET_WORKERS_FAILURE: 'GET_WORKERS_FAILURE',

	RESET_WORKER_STATE: 'RESET_WORKER_STATE',

	CHECK_USER_EMAIL_REQUEST: 'CHECK_USER_EMAIL_REQUEST',
	CHECK_USER_EMAIL_SUCCESS: 'CHECK_USER_EMAIL_SUCCESS',
	CHECK_USER_EMAIL_FAILURE: 'CHECK_USER_EMAIL_FAILURE',

	SEND_USER_INVITE_REQUEST: 'SEND_USER_INVITE_REQUEST',
	SEND_USER_INVITE_SUCCESS: 'SEND_USER_INVITE_SUCCESS',
	SEND_USER_INVITE_FAILURE: 'SEND_USER_INVITE_FAILURE',

	ATTACH_USER_TO_COMPANY_REQUEST: 'ATTACH_USER_TO_COMPANY_REQUEST',
	ATTACH_USER_TO_COMPANY_SUCCESS: 'ATTACH_USER_TO_COMPANY_SUCCESS',
	ATTACH_USER_TO_COMPANY_FAILURE: 'ATTACH_USER_TO_COMPANY_FAILURE',

	AUTOMATIC_REFRESH_WORKING_TIME_REQUEST: 'AUTOMATIC_REFRESH_WORKING_TIME_REQUEST',
	AUTOMATIC_REFRESH_WORKING_TIME_SUCCESS: 'AUTOMATIC_REFRESH_WORKING_TIME_SUCCESS',
	AUTOMATIC_REFRESH_WORKING_TIME_FAILURE: 'AUTOMATIC_REFRESH_WORKING_TIME_FAILURE',

	GET_NOTES_REQUEST: 'GET_NOTES_REQUEST',
	GET_NOTES_SUCCESS: 'GET_NOTES_SUCCESS',
	GET_NOTES_FAILURE: 'GET_NOTES_FAILURE',

	SET_NOTES_REQUEST: 'SET_NOTES_REQUEST',
	SET_NOTES_SUCCESS: 'SET_NOTES_SUCCESS',
	SET_NOTES_FAILURE: 'SET_NOTES_FAILURE',

	RESET_NOTES: 'RESET_NOTES',

	RESET_NOTES_FAILED: 'RESET_NOTES_FAILED'
};
