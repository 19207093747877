import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { sendError } from './services/errorService';
import { useErrorBoundary } from 'use-error-boundary';
import ReactGa from 'react-ga';
import { createHashHistory } from 'history';
import { TheInfoBox } from './containers';
import { setItem } from './helpers/storage';
import { ToastContainer } from 'react-toastify';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const WorkerRegistration = React.lazy(() => import('./views/pages/worker/WorkerRegistration'));
const ActivateAccount = React.lazy(() => import('./views/pages/login/ActivateAccount'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ChangeCompany = React.lazy(() => import('./views/pages/company/ChangeCompany'));

const history = createHashHistory();

function App({ t }) {
	// const { infoBoxHidden } = useSelector((state) => state.changeState);
	const { ErrorBoundary } = useErrorBoundary({
		onDidCatch: (error, errorInfo) => {
			sendError(error.message, errorInfo.componentStack);
		}
	});

	useEffect(() => {
		ReactGa.initialize('UA-190589873-1');
		/*G-2SZ1Y15DSE is new generation of Google Analytics, 
		according to https://github.com/react-ga/react-ga/issues/460
		it is not yet supported by react-ga*/
		//ReactGa.initialize("G-2SZ1Y15DSE");
		//to report page view
		try {
			const splittedPathname = window.location.pathname.split('/');
			if (splittedPathname[splittedPathname.length - 1] === 'en') {
				i18next.changeLanguage('en');
				setItem('lng', 'en');
			}
		} catch (error) {}
		history.listen((l) => {
			ReactGa.set({ page: l.location.pathname });
			ReactGa.pageview(l.location.pathname);
		});
	}, []);

	return (
		<>
			<BrowserRouter>
				<React.Suspense fallback={loading}>
					<ErrorBoundary renderError={({ err }) => <Page500 error={err} />}>
						<Switch>
							<Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
							<Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
							<Route exact path="/register/en" name="Register Page" render={(props) => <Register {...props} />} />
							<Route
								exact
								path="/set-password/:userHash"
								name="Worker registration"
								render={(props) => <WorkerRegistration {...props} />}
							/>
							<Route exact path="/activate/:hash/:type" name="Account verifying" render={(props) => <ActivateAccount {...props} />} />
							<Route exact path="/change-company/:hash" name="Change company" render={(props) => <ChangeCompany {...props} />} />
							<Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
							<Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
							<Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
						</Switch>
					</ErrorBoundary>
					{/* {!infoBoxHidden && <TheInfoBox />} */}
					<TheInfoBox />
				</React.Suspense>
			</BrowserRouter>
			<ToastContainer />
		</>
	);
}

export default App;
