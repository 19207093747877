import { promoCodeConstants } from '../constants/promoCodeConstants';

export function promoCodeReducer(
	state = {
		promoCodes: []
	},
	action = {}
) {
	switch (action.type) {
		// getAllPromoCodes
		case promoCodeConstants.GET_ALL_PROMO_CODES_REQUEST:
			return {
				...state,
				promoCodesLoading: true
			};
		case promoCodeConstants.GET_ALL_PROMO_CODES_SUCCESS:
			return {
				...state,
				promoCodesLoading: false,
				promoCodes: action.result.items
			};
		// addPromoCode
		case promoCodeConstants.ADD_PROMO_CODE_SUCCESS:
			return {
				...state,
				promoCodes: action.result.items
			};
		// editPromoCode
		case promoCodeConstants.EDIT_PROMO_CODE_SUCCESS:
			return {
				...state,
				promoCodes: action.result.items
			};
		// deletePromoCode
		case promoCodeConstants.DELETE_PROMO_CODE_SUCCESS:
			return {
				...state,
				promoCodes: action.result.items
			};
		default:
			return {
				...state
			};
	}
}
