import React from 'react';

const ThreeHorizontalLinesComponent = () => {
	return (
		<div className="menu-icon">
			<span className="line"></span>
			<span className="line"></span>
			<span className="line"></span>
		</div>
	);
};

export default ThreeHorizontalLinesComponent;
