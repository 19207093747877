import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import StepsWizzardComponent from '../views/components/StepsWizzardComponent';
import { sideBarConstants } from '../constants';
import { classnames } from '../helpers';

const TheInfoBox = ({ t }) => {
	const dispatch = useDispatch();
	const { infoBoxOpen } = useSelector((state) => state.changeState);
	const infoBoxClassName = classnames('the-info-box', !infoBoxOpen && 'the-info-box--closed');

	return (
		<>
			<div className="the-info-box__closed">
				<span
					onClick={() => {
						dispatch({ type: sideBarConstants.INFO_BOX_OPEN, open: true });
					}}>
					{t('label.manual')}
				</span>
			</div>
			<div className={infoBoxClassName}>
				{infoBoxOpen && (
					<span
						className="the-info-box__close"
						onClick={() => {
							dispatch({ type: sideBarConstants.INFO_BOX_OPEN, open: false });
						}}></span>
				)}
				<div className="the-info-box__header">{t('label.guide_through')}</div>
				<div className="the-info-box__title">{t('label.if_you_are_new')}</div>
				<StepsWizzardComponent />
				<div className="the-info-box__title">
					<span>{t('label.in_case_of_ambiguity')}</span>
					<a href="mailto:info@bookall.rs">info@bookall.rs</a>
					<span>{t('label.and_on_phone_number')}</span>
					<a href="tel:+381659099959">+381659099959</a>
				</div>
				<div className="the-info-box__video-tutorial">
					<a href="https://www.youtube.com/watch?v=NxURFXrMovs" target="_blank" rel="noopener noreferrer">
						{t('label.video_tutorial')}
					</a>
				</div>
			</div>
		</>
	);
};

export default withNamespaces()(TheInfoBox);
