import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { classnames } from '../../helpers';

const CheckboxComponent = ({ t, onChange, checked, readOnly }) => {
	const [isChecked, setIsChecked] = useState(checked);
	const checkboxClassName = classnames('checkbox-component', isChecked && 'checkbox-component--checked');

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleOnChange = () => {
		setIsChecked(!isChecked);
		onChange && onChange(!isChecked);
	};

	return (
		<label className={checkboxClassName}>
			<input type="checkbox" checked={isChecked} onChange={handleOnChange} readOnly={readOnly} />
			<span className="checkbox-component__span">
				{isChecked && (
					<svg viewBox="0 0 12 10">
						<path
							fill="#FFF"
							fillRule="evenodd"
							d="M4.716 7.558L1.646 4.96A1 1 0 00.354 6.486l3.872 3.277a1 1 0 001.44-.155l6.128-8A1 1 0 0010.206.392l-5.49 7.166z"></path>
					</svg>
				)}
			</span>
		</label>
	);
};

export default withNamespaces()(CheckboxComponent);
