export const companyConstants = {
	GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
	GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
	GET_COMPANIES_FAILURE: 'GET_COMPANIES_FAILURE',

	GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
	GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
	GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

	ADD_COMPANY_REQUEST: 'ADD_COMPANY_REQUEST',
	ADD_COMPANY_SUCCESS: 'ADD_COMPANY_SUCCESS',
	ADD_COMPANY_FAILURE: 'ADD_COMPANY_FAILURE',

	EDIT_COMPANY_REQUEST: 'EDIT_COMPANY_REQUEST',
	EDIT_COMPANY_SUCCESS: 'EDIT_COMPANY_SUCCESS',
	EDIT_COMPANY_FAILURE: 'EDIT_COMPANY_FAILURE',

	RESET_COMPANY_ADD_EDIT_STATE: 'RESET_COMPANY_ADD_EDIT_STATE',

	DELETE_COMPANY_REQUEST: 'DELETE_COMPANY_REQUEST',
	DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
	DELETE_COMPANY_FAILURE: 'DELETE_COMPANY_FAILURE',

	SET_COMPANIES: 'SET_COMPANIES',

	CHANGE_WORKER_ACTIVE_COMPANY_REQUEST: 'CHANGE_WORKER_ACTIVE_COMPANY_REQUEST',
	CHANGE_WORKER_ACTIVE_COMPANY_SUCCESS: 'CHANGE_WORKER_ACTIVE_COMPANY_SUCCESS',
	CHANGE_WORKER_ACTIVE_COMPANY_FAILURE: 'CHANGE_WORKER_ACTIVE_COMPANY_FAILURE',

	GET_COMPANIES_BY_USER_REQUEST: 'GET_COMPANIES_BY_USER_REQUEST',
	GET_COMPANIES_BY_USER_SUCCESS: 'GET_COMPANIES_BY_USER_SUCCESS',
	GET_COMPANIES_BY_USER_FAILURE: 'GET_COMPANIES_BY_USER_FAILURE'
};
