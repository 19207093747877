import React from 'react';
import { sideBarConstants } from '../constants';
import { FirstStepComponent, SecondStepComponent, ThirdStepComponent, FourthStepComponent, FifthStepComponent } from '../views/components';
import { isMobileDeviceWidth } from '../helpers';

const initialState = {
	sidebarShow: isMobileDeviceWidth() ? false : true,
	steps: [
		{
			id: 1,
			component: <FirstStepComponent />
		},
		{
			id: 2,
			component: <SecondStepComponent />
		},
		{
			id: 3,
			component: <ThirdStepComponent />
		},
		{
			id: 4,
			component: <FourthStepComponent />
		},
		{
			id: 5,
			component: <FifthStepComponent />
		}
	],
	infoBoxOpen: false,
	infoBoxHidden: true
};

export function changeState(state = initialState, { type, stepId, finished, open = false, hidden = false }) {
	switch (type) {
		case sideBarConstants.OPEN_CLOSE_SIDE_BAR:
			return { ...state, sidebarShow: !state.sidebarShow };
		case sideBarConstants.CHANGE_STEP:
			return {
				...state,
				steps: state.steps.map((step) => {
					if (step.id === stepId) {
						step.finished = finished;
					}
					return step;
				})
			};
		case sideBarConstants.INFO_BOX_OPEN:
			return {
				...state,
				infoBoxOpen: open,
				infoBoxHidden: false
			};
		case sideBarConstants.INFO_BOX_HIDDEN:
			return {
				...state,
				infoBoxHidden: hidden
			};
		default:
			return state;
	}
}
