import { categoryConstants } from '../constants/categoryConstants';

export function categoryReducer(
	state = {
		categories: []
	},
	action = {}
) {
	switch (action.type) {
		case categoryConstants.GET_CATEGORIES_REQUEST:
			return {
				...state,
				categoriesLoading: true,
				categoriesFailed: false
			};
		case categoryConstants.GET_CATEGORIES_SUCCESS:
			return {
				...state,
				categoriesLoading: false,
				categories: action.result,
				categoriesFailed: false
			};
		case categoryConstants.GET_CATEGORIES_FAILURE:
			return {
				...state,
				categoriesLoading: false,
				categoriesFailed: true
			};
		case categoryConstants.DELETE_CATEGORY_REQUEST:
			return {
				...state,
				deleteCategoryFailedMessage: '',
				deletedCategory: false
			};
		case categoryConstants.DELETE_CATEGORY_SUCCESS:
			return {
				...state,
				deletedCategory: true
			};
		case categoryConstants.DELETE_CATEGORY_FAILURE:
			return {
				...state,
				deletedCategory: false,
				deleteCategoryFailedMessage: action.message
			};
		case categoryConstants.ADD_CATEGORY_REQUEST:
			return {
				...state,
				addedCategory: false,
				addedCategoryFailedMessage: ''
			};
		case categoryConstants.ADD_CATEGORY_SUCCESS:
			return {
				...state,
				addedCategory: true,
				categories: [...state.categories, action.result]
			};
		case categoryConstants.ADD_CATEGORY_FAILURE:
			return {
				...state,
				addedCategory: false,
				addedCategoryFailedMessage: action.message
			};
		case categoryConstants.EDIT_CATEGORY_REQUEST:
			return {
				...state,
				editedCategory: false,
				editedCategoryFailedMessage: ''
			};
		case categoryConstants.EDIT_CATEGORY_SUCCESS:
			let editedCategories = [];
			editedCategories = state.categories.map((category) => {
				return category.id === action.result.id ? action.result : category;
			});
			return {
				...state,
				editedCategory: true,
				categories: editedCategories
			};
		case categoryConstants.EDIT_CATEGORY_FAILURE:
			return {
				...state,
				editedCategory: true,
				editedCategoryFailedMessage: action.message
			};
		default:
			return {
				...state
			};
	}
}
