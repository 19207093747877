import axios from 'axios';
import { createOKResponse, createErrorResponse } from './responses';
import config from './config';
import { getToken } from './authService';

export async function getCompanies(categoryId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/company/get-all?categoryId=${categoryId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.get_companies_error');
	}
}

export async function getCompany(companyId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/company/get-company?companyId=${companyId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.get_companies_error');
	}
}

export async function getCompaniesByUser(userId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/company/get-companies?userId=${userId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.get_companies_error');
	}
}

export async function changeWorkerActiveCompany(companyId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/user/changeCompany`,
			data: {
				companyId
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.something_went_wrong');
	}
}

export async function addCompany(
	categoryId,
	companyName,
	companyShortDesc,
	companyLongDesc,
	newImages,
	mondayTimeFrom,
	mondayTimeTo,
	tuesdayTimeFrom,
	tuesdayTimeTo,
	wednesdayTimeFrom,
	wednesdayTimeTo,
	thursdayTimeFrom,
	thursdayTimeTo,
	fridayTimeFrom,
	fridayTimeTo,
	saturdayTimeFrom,
	saturdayTimeTo,
	sundayTimeFrom,
	sundayTimeTo,
	address,
	area,
	city,
	latitude,
	longitude,
	companyPhoneNumber,
	companyUrl,
	legalEntity,
	payWithCard,
	payWithCash,
	companyAccount,
	companyLogo,
	serviceBreak,
	companyInstagram,
	companyFacebook,
	companyFake = false,
	locationOnline
) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/company/add`,
			data: {
				categoryId,
				companyName,
				companyShortDesc,
				companyLongDesc,
				newImages,
				mondayTimeFrom,
				mondayTimeTo,
				tuesdayTimeFrom,
				tuesdayTimeTo,
				wednesdayTimeFrom,
				wednesdayTimeTo,
				thursdayTimeFrom,
				thursdayTimeTo,
				fridayTimeFrom,
				fridayTimeTo,
				saturdayTimeFrom,
				saturdayTimeTo,
				sundayTimeFrom,
				sundayTimeTo,
				address,
				area,
				city,
				latitude,
				longitude,
				companyPhoneNumber,
				companyUrl,
				legalEntity,
				payWithCard,
				payWithCash,
				companyAccount,
				companyLogo,
				serviceBreak,
				companyInstagram,
				companyFacebook,
				companyFake,
				locationOnline
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.add_company_error');
	}
}

export async function editCompany(
	companyId,
	categoryId,
	companyName,
	companyShortDesc,
	companyLongDesc,
	newImages,
	deleteImages,
	mondayTimeFrom,
	mondayTimeTo,
	tuesdayTimeFrom,
	tuesdayTimeTo,
	wednesdayTimeFrom,
	wednesdayTimeTo,
	thursdayTimeFrom,
	thursdayTimeTo,
	fridayTimeFrom,
	fridayTimeTo,
	saturdayTimeFrom,
	saturdayTimeTo,
	sundayTimeFrom,
	sundayTimeTo,
	address,
	area,
	city,
	latitude,
	longitude,
	companyPhoneNumber,
	companyUrl,
	legalEntity,
	payWithCard,
	payWithCash,
	companyAccount,
	companyLogo,
	serviceBreak,
	companyInstagram,
	companyFacebook,
	companyFake = false,
	locationOnline
) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/company/edit`,
			data: {
				companyId,
				categoryId,
				companyName,
				companyShortDesc,
				companyLongDesc,
				newImages,
				deleteImages,
				mondayTimeFrom,
				mondayTimeTo,
				tuesdayTimeFrom,
				tuesdayTimeTo,
				wednesdayTimeFrom,
				wednesdayTimeTo,
				thursdayTimeFrom,
				thursdayTimeTo,
				fridayTimeFrom,
				fridayTimeTo,
				saturdayTimeFrom,
				saturdayTimeTo,
				sundayTimeFrom,
				sundayTimeTo,
				address,
				area,
				city,
				latitude,
				longitude,
				companyPhoneNumber,
				companyUrl,
				legalEntity,
				payWithCard,
				payWithCash,
				companyAccount,
				companyLogo,
				serviceBreak,
				companyInstagram,
				companyFacebook,
				companyFake,
				locationOnline
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.edit_company_error');
	}
}

export async function deleteCompany(companyId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/company/delete/${companyId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.delete_company_error');
	}
}

export async function getAllEvents(companyId, from, to) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				Authorization: token
			},
			url: `${config.url}/company/get-slots?companyId=${companyId}&from=${from}&to=${to}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.save_events_error');
	}
}

export async function saveAllEvents(companyId, events, from, to) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/company/set-slots`,
			data: { companyId, events, from, to }
		});

		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.save_events_error');
	}
}
