import { serviceConstants } from '../constants/serviceConstants';

export function serviceReducer(
	state = {
		serviceCategories: []
	},
	action = {}
) {
	switch (action.type) {
		case serviceConstants.GET_SERVICE_CATEGORIES_REQUEST:
			return {
				...state,
				serviceCategoriesLoading: true,
				serviceCategoriesFailed: false
			};
		case serviceConstants.GET_SERVICE_CATEGORIES_SUCCESS:
			return {
				...state,
				serviceCategoriesLoading: false,
				serviceCategories: action.result,
				serviceCategoriesFailed: false
			};
		case serviceConstants.GET_SERVICE_CATEGORIES_FAILURE:
			return {
				...state,
				serviceCategoriesLoading: false,
				serviceCategoriesFailed: true
			};
		case serviceConstants.ADD_SERVICE_CATEGORY_REQUEST:
			return {
				...state,
				addedServiceCategory: false,
				addedServiceCategoryFailedMessage: ''
			};
		case serviceConstants.ADD_SERVICE_CATEGORY_SUCCESS:
			return {
				...state,
				addedServiceCategory: true,
				serviceCategories: action.result
			};
		case serviceConstants.ADD_SERVICE_CATEGORY_FAILURE:
			return {
				...state,
				addedServiceCategory: false,
				addedServiceCategoryFailedMessage: action.message
			};
		case serviceConstants.EDIT_SERVICE_CATEGORY_REQUEST:
		case serviceConstants.EDIT_SERVICE_CATEGORY_ORDER_REQUEST:
			return {
				...state,
				editedServiceCategory: false,
				editedServiceCategoryFailedMessage: '',
				editingServiceCategory: true
			};
		case serviceConstants.EDIT_SERVICE_CATEGORY_SUCCESS:
			let editedServiceCategories = [];
			editedServiceCategories = [...state.serviceCategories].map((serviceCategory) => {
				return serviceCategory.categoryId === action.result.categoryId ? action.result : serviceCategory;
			});
			editedServiceCategories.sort(function (x, y) {
				return +x.orderId - +y.orderId;
			});
			return {
				...state,
				editedServiceCategory: true,
				serviceCategories: editedServiceCategories,
				editingServiceCategory: false
			};
		case serviceConstants.EDIT_SERVICE_CATEGORY_ORDER_SUCCESS:
			return {
				...state,
				editedServiceCategory: true,
				serviceCategories: action.result,
				editingServiceCategory: false
			};
		case serviceConstants.EDIT_SERVICE_CATEGORY_FAILURE:
		case serviceConstants.EDIT_SERVICE_CATEGORY_ORDER_FAILURE:
			return {
				...state,
				editedServiceCategory: true,
				editedServiceCategoryFailedMessage: action.message
			};
		case serviceConstants.DELETE_SERVICE_CATEGORY_REQUEST:
			return {
				...state,
				deleteServiceCategoryFailedMessage: '',
				deletedServiceCategory: false
			};
		case serviceConstants.DELETE_SERVICE_CATEGORY_SUCCESS:
			return {
				...state,
				deletedServiceCategory: true,
				serviceCategories: [...state.serviceCategories].filter((sc) => sc.categoryId !== action.categoryId)
			};
		case serviceConstants.DELETE_SERVICE_CATEGORY_FAILURE:
			return {
				...state,
				deletedServiceCategory: false,
				deleteServiceCategoryFailedMessage: action.message
			};
		case serviceConstants.ADD_SERVICE_REQUEST:
			return {
				...state,
				addedService: false,
				addedServiceFailedMessage: ''
			};
		case serviceConstants.ADD_SERVICE_SUCCESS:
			return {
				...state,
				addedService: true,
				serviceCategories: state.serviceCategories.map((sc) => {
					if (sc.categoryId === action.result[0]?.categoryId) {
						sc.services = action.result;
					}
					return sc;
				})
			};
		case serviceConstants.ADD_SERVICE_FAILURE:
			return {
				...state,
				addedService: false,
				addedServiceFailedMessage: action.message
			};

		case serviceConstants.EDIT_SERVICE_ORDER_REQUEST:
			return {
				...state,
				editedOrderService: false,
				editedOrderServiceFailedMessage: '',
				editingServiceOrder: true
			};
		case serviceConstants.EDIT_SERVICE_ORDER_SUCCESS:
			const resultServices = action.result;

			let editedCategoriesEditServiceOrder = state.serviceCategories.map((sc) => {
				if (sc.categoryId === action.categoryId) {
					sc.services = resultServices;
					return sc;
				} else {
					return sc;
				}
			});
			return {
				...state,
				editedService: true,
				serviceCategories: editedCategoriesEditServiceOrder,
				editingServiceOrder: false
			};
		case serviceConstants.EDIT_SERVICE_ORDER_FAILURE:
			return {
				...state,
				editedOrderService: false,
				editedServiceFailedMessage: action.message
			};
		case serviceConstants.EDIT_SERVICE_REQUEST:
			return {
				...state,
				editedService: false,
				editedOrderServiceFailedMessage: ''
			};
		case serviceConstants.EDIT_SERVICE_SUCCESS:
			return {
				...state,
				editedService: true,
				serviceCategories: action.result
			};
		case serviceConstants.EDIT_SERVICE_FAILURE:
			return {
				...state,
				editedService: false,
				editedServiceFailedMessage: action.message
			};
		case serviceConstants.DELETE_SERVICE_REQUEST:
			return {
				...state,
				deleteServiceFailedMessage: '',
				deletedService: false
			};
		case serviceConstants.DELETE_SERVICE_SUCCESS:
			return {
				...state,
				deletedService: true,
				serviceCategories: state.serviceCategories.map((sc) => {
					if (sc.categoryId === action.categoryId) {
						sc.services = action.result;
					}
					return sc;
				})
			};
		case serviceConstants.DELETE_SERVICE_FAILURE:
			return {
				...state,
				deletedService: false,
				deleteServiceFailedMessage: action.message
			};
		default:
			return {
				...state
			};
	}
}
