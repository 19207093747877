import { authentication } from './authReducer';
import { workerReducer } from './workerReducer';
import { changeState } from './sideBarReducer';
import { chatReducer } from './chatReducer';
import { appointmentReducer } from './appoinmentReducer';
import { paymentHistoryReducer } from './paymentHistoryReducer';
import { contactSupportReducer } from './contactSupportReducer';
import { categoryReducer } from './categoryReducer';
import { companyReducer } from './companyReducer';
import { serviceReducer } from './serviceReducer';
import { promoCodeReducer } from './promoCodeReducer';
import { authConstants } from '../constants';
import { combineReducers } from 'redux';

const appReducer = combineReducers({
	authentication,
	workerReducer,
	changeState,
	chatReducer,
	appointmentReducer,
	paymentHistoryReducer,
	contactSupportReducer,
	categoryReducer,
	companyReducer,
	serviceReducer,
	promoCodeReducer
});

const rootReducer = (state, action) => {
	if (action.type === authConstants.LOGOUT_SUCCESS) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
