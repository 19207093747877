import { createBrowserHistory } from 'history';
import { sendError } from '../services/errorService';
import ReactGa from 'react-ga';
import { useRef } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { globalConstants } from '../constants';

export const history = createBrowserHistory();
export const ACCEPTED_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];
export const MAX_FILE_FILE_SIZE = 5242880; // size in bytes

export const isNull = (obj) => {
	return obj === undefined || obj === null;
};

export function changeRoute(route = '/') {
	history.push(route);
}

export function getHistory() {
	return history;
}

export function getLocation() {
	return window.location.pathname;
}

export function validateEmptyString(string) {
	try {
		if (string && !string.match(/^ *$/)) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - validateEmptyString - string: ${string}`, error);
		return true;
	}
}

export function isObjectEmpty(obj) {
	try {
		return Object.keys(obj).length === 0;
	} catch (error) {
		sendError(`Utils - isObjectEmpty - obj:${JSON.stringify(obj)} - `, error);
	}
}

export function validateNumbersRangeFrom0To100(string) {
	try {
		if (string && string.toString().match(/^([0-9]|[1-9][0-9])$/)) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - validateNumbersRangeFrom0To100 - string: ${string}`, error);
		return true;
	}
}

export function numberRegex(string) {
	try {
		if (string && string.toString().match(/^\d+$/)) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - numberRegex - string: ${string}`, error);
		return true;
	}
}

export function hoursTimeRegex(string) {
	try {
		if (string && string.toString().match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - hoursTimeRegex - string: ${string}`, error);
		return true;
	}
}

export function validateLettersAndSpace(string) {
	try {
		if (string && string.match(/^[a-žA-Ž\s]*$/)) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - validateLettersAndSpace - string: ${string}`, error);
		return true;
	}
}

export function validateCategoryName(string) {
	try {
		if (string && string.match(/^[&a-žA-Ž\s]*$/)) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - validateCategoryName - string: ${string}`, error);
		return true;
	}
}

export function validatePassword(string) {
	try {
		if (string && !string.match(/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - validatePassword - string: ${string}`, error);
		return true;
	}
}

export function validateUrl(string) {
	try {
		if (
			!validateEmptyString(string) ||
			//eslint-disable-next-line
			(string &&
				string.match(
					/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z/]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/
				))
		) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - validateUrl - string: ${string}`, error);
		return true;
	}
}

export function validateEmail(email) {
	try {
		return typeof email === 'string' && email.length > 4 && /\S+@\S+\.\S+/.test(email);
	} catch (error) {
		sendError(`Utils - validateEmail - email: ${email}`, error);
		return true;
	}
}

export function validatePhoneNumber(phoneNumber) {
	try {
		if (phoneNumber && phoneNumber.length > 7 && phoneNumber.toString().match(/^\d+$/)) {
			return true;
		}
		return false;
	} catch (error) {
		sendError(`Utils - validatePhoneNumber - phoneNumber: ${phoneNumber}`, error);
		return true;
	}
}
export const validImageRegex = () => {
	return '.(gif|jpe?g|tiff?|png|webp|bmp)$';
};

export function validateIsStringLengthLongerThan(string, length) {
	return string && string.length > length;
}

export function getLanguages() {
	return [
		{
			value: 'srb',
			label: 'SRB'
		},
		{
			value: 'en',
			label: 'ENG'
		}
	];
}

// need to make a service to get all roles
export function getAllRoles() {
	return [
		{
			value: '1',
			label: 'headAdmin'
		},
		{
			value: '2',
			label: 'Admin'
		},
		{
			value: '3',
			label: 'Worker'
		},
		{
			value: '4',
			label: 'Client'
		}
	];
}

export function getRoles() {
	return [
		{
			value: '2',
			label: 'Admin'
		},
		{
			value: '3',
			label: 'Worker'
		}
	];
}

export function getGenders() {
	return [
		{
			value: 'both',
			label: i18n.t('label.both')
		},
		{
			value: 'male',
			label: i18n.t('label.male')
		},
		{
			value: 'female',
			label: i18n.t('label.female')
		}
	];
}

export function getBlob(type, fileContent) {
	const binaryString = atob(fileContent);
	const binaryLen = binaryString.length;
	const bytes = new Uint8Array(binaryLen);
	for (let i = 0; i < binaryLen; i += 1) {
		let ascii = binaryString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return new Blob([bytes], { type: type });
}

export function getUrl(urlPath) {
	if (urlPath.startsWith('/')) {
		return getContextPath() + urlPath;
	}

	return urlPath;
}

export function readFileUrl(file, callback) {
	if (file) {
		const reader = new FileReader();
		if (callback) {
			reader.onloadend = () => callback(reader.result);
		}
		reader.readAsDataURL(file);
	}
}

export function valOrDefault(val, def) {
	if (isNull(val)) {
		return def;
	}
	return val;
}

export function toDataUrl(url, callback) {
	var xhr = new XMLHttpRequest();
	xhr.onload = function () {
		var reader = new FileReader();
		reader.onloadend = function () {
			callback(reader.result);
		};
		reader.readAsDataURL(xhr.response);
	};
	xhr.open('GET', url);
	xhr.responseType = 'blob';
	xhr.send();
}

export function getBase64(file, callback) {
	try {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			callback(reader.result);
		};
		reader.onerror = function (error) {
			sendError('Utils method - getBase64()', error.message);
		};
	} catch (error) {
		sendError('Utils - getBase64()', error.message);
	}
}

export function makeBlobRequest(url) {
	return new Promise(function (resolve, reject) {
		let xhr = new XMLHttpRequest();
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.onload = function () {
			var reader = new FileReader();
			return new Promise(function (r, reject) {
				reader.onloadend = function () {
					resolve(reader.result);
				};
				reader.readAsDataURL(xhr.response);
			});
		};
		xhr.onerror = function () {
			reject({
				status: this.status,
				statusText: xhr.statusText
			});
		};
		xhr.send();
	});
}

export function arrayBufferToBase64(file, callback) {
	let binary = '';
	let reader = new FileReader();
	reader.onload = function () {
		let bytes = new Uint8Array(this.result);
		let len = bytes.byteLength;
		for (let i = 0; i < len; i += 1) {
			binary += String.fromCharCode(bytes[i]);
		}
		callback(window.btoa(binary));
	};
	reader.readAsArrayBuffer(file);
}

export function base64toFile(str) {
	const decoded = window.atob(str).replace(/(?:\r\n|\r|\n)/g, '<br>');
	return decodeURIComponent(
		decoded
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);
}

export async function dataURLtoFile(dataurl, filename) {
	const data = await fetch(dataurl);
	const blobData = await data.blob();
	const file = new File([blobData], filename, { type: 'application/pdf' });
	return file;
}

function getContextPath() {
	const pathArray = window.location.pathname.split('/').filter((segment) => segment.length > 0 && segment.length <= 5);

	return `/${pathArray.join('/')}`;
}

export function googleAnalyticsEventTrack(category, action, label) {
	ReactGa.event({
		category: category,
		action: action,
		label: label,
		value: 1
	});
}

export function groupArrays(array) {
	try {
		const groups = array.reduce((groups, message) => {
			const date = message.dateTime?.split(' ')[0];
			if (!groups[date]) {
				groups[date] = [];
			}
			groups[date].push(message);
			return groups;
		}, {});

		return Object.keys(groups).map((date) => {
			return {
				date,
				messages: groups[date]
			};
		});
	} catch (error) {
		sendError(error.message, error.stack);
	}
}

export function getYears() {
	const currentYear = new Date().getFullYear();
	return [
		{ value: currentYear - 2, label: currentYear - 2 },
		{ value: currentYear - 1, label: currentYear - 1 },
		{ value: currentYear, label: currentYear }
	];
}

export function getMonths(t) {
	return [
		{ value: 'jan', label: t('label.january') },
		{ value: 'feb', label: t('label.february') },
		{ value: 'mar', label: t('label.march') },
		{ value: 'apr', label: t('label.april') },
		{ value: 'may', label: t('label.may') },
		{ value: 'jun', label: t('label.june') },
		{ value: 'jul', label: t('label.july') },
		{ value: 'aug', label: t('label.august') },
		{ value: 'sep', label: t('label.september') },
		{ value: 'okt', label: t('label.october') },
		{ value: 'nov', label: t('label.november') },
		{ value: 'dec', label: t('label.december') }
	];
}

export function PHOTO_CAMERA(width = 24, height = 24, minX = 0, minY = 0, viewBoxWidth = width, viewBoxHeight = height) {
	return `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="${width}px" height="${height}px" viewBox="${minX} ${minY} ${viewBoxWidth} ${viewBoxHeight}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: sketchtool 52.2 (67145) - http://www.bohemiancoding.com/sketch -->
        <title>80CF4EBC-F009-489E-82D8-A105F7BB15D8</title>
        <desc>Created with sketchtool.</desc>
        <g id="Pricing-Form-Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.48">
            <g id="15_Upload" transform="translate(-166.000000, -413.000000)" fill="#000000" fill-rule="nonzero">
                <g id="Group-4" transform="translate(0.000000, 187.000000)">
                    <g id="Group-8" transform="translate(40.000000, 170.000000)">
                        <g id="Group-4" transform="translate(52.000000, 52.000000)">
                            <g id="sharp-photo_camera-24px-(1)" transform="translate(70.000000, 0.000000)">
                                <path d="M18,4 L14.34,8 L4,8 L4,40 L44,40 L44,8 L33.66,8 L30,4 L18,4 Z M24,34 C18.48,34 14,29.52 14,24 C14,18.48 18.48,14 24,14 C29.52,14 34,18.48 34,24 C34,29.52 29.52,34 24,34 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>`;
}

export function isMobileDeviceWidth() {
	return window.innerWidth < 768;
}

export function isTabletLandscapeWidth() {
	return window.innerWidth <= 992;
}

export function isLaptopWidth() {
	return window.innerWidth < 1400;
}

export function mapCompanies(companies, action) {
	try {
		return companies.map((company) => {
			return {
				...company,
				value: company.id,
				label: company.companyName,
				userStatus: company.userStatus
			};
		});
	} catch (error) {
		sendError(`utils - mapCompanies() - ${action}`, error.message);
		return [];
	}
}
export const useFocus = () => {
	try {
		const htmlElRef = useRef(null);
		const setFocus = () => {
			htmlElRef.current && htmlElRef.current.focus();
		};

		return [htmlElRef, setFocus];
	} catch (error) {
		sendError('utils - useFocus() - ', error.message);
	}
};

export function getRedirectUrl(userAttributes) {
	switch (userAttributes.role && userAttributes.role.roleName) {
		case 'headAdmin':
			return '/categories';
		case 'companyAdmin':
			return '/company';
		case 'worker':
			return '/timesheet';
		default:
			return '/';
	}
}

export function startTimeValidation(start, end) {
	return moment(end, globalConstants.TIME_FORMAT).isAfter(moment(start, globalConstants.TIME_FORMAT));
}

export function endTimeValidation(start, end) {
	try {
		if (start && end) {
			return moment(start, globalConstants.TIME_FORMAT).isBefore(moment(end, globalConstants.TIME_FORMAT));
		}
		return true;
	} catch (error) {
		sendError('utils - endTimeValidation() - ', error);
	}
}

export function mapCategories(categories) {
	return categories.map((c) => {
		return mapCategory(c);
	});
}

export function mapCategory(category) {
	return {
		...category,
		id: category.categoryId,
		value: category.categoryId,
		label: category.categoryName
	};
}

export const isStringEmpty = (string) => {
	return string === null || string === undefined || string === '';
};

// function to upper first letter of string
export const upperFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const classnames = (...args) => {
	try {
		let classes = [];
		args.forEach((arg) => {
			if (!arg) return;
			const argType = typeof arg;
			if (argType === 'string' || argType === 'number') {
				classes.push(arg);
			} else if (Array.isArray(arg)) {
				const inner = classnames(...arg);
				if (inner) {
					classes.push(inner);
				}
			} else if (argType === 'object') {
				if (arg.toString !== Object.prototype.toString && !arg.toString.toString().includes('[native code]')) {
					classes.push(arg.toString());
				} else {
					Object.entries(arg).forEach(([key, value]) => {
						if (value) {
							classes.push(key);
						}
					});
				}
			}
		});
		return classes.join(' ');
	} catch (error) {
		sendError('utils - classnames() - ', error);
		return '';
	}
};
