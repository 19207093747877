import { contactSupportConstants } from '../constants/contactSupportConstants';

export function contactSupportReducer(
	state = {
		messageSendLoading: true
	},
	action = {}
) {
	switch (action.type) {
		case contactSupportConstants.MESSAGE_CONTACT_SUPPORT_REQUEST:
			return {
				...state,
				messageSendLoading: true
			};
		case contactSupportConstants.MESSAGE_CONTACT_SUPPORT_SUCCESS:
			return {
				...state,
				messageSendLoading: false
			};
		case contactSupportConstants.MESSAGE_CONTACT_SUPPORT_FALIURE:
			return {
				...state,
				messageSendLoading: false,
				messageSendFailed: true
			};
		default:
			return state;
	}
}
