import React from 'react';
import { useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { sideBarConstants } from '../../../constants';

const FourthStepComponent = ({ t }) => {
	const dispatch = useDispatch();

	return (
		<div className="steps-wizzard__content">
			<span>{t('label.do_not_forget_to_enter_the_working_hours')}</span>
			<NavLink
				to="/timesheet"
				onClick={() => {
					dispatch({ type: sideBarConstants.INFO_BOX_OPEN, open: false });
				}}>
				{t('label.timesheet').toUpperCase()}
			</NavLink>
			<span>{t('label.working_hours_is_needed')}</span>
		</div>
	);
};

export default withNamespaces()(FourthStepComponent);
