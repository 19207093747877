import * as authService from '../services/authService';
import { authConstants, workerConstants } from '../constants';
import { refreshTokenSetup, setItem } from '../helpers';
import i18next from 'i18next';
import { setCompanies } from '.';

export function login(email, password, selectedLang) {
	return async (dispatch) => {
		dispatch(loginRequest({ email }));

		let result = await authService.login(email, password);
		if (result.OK) {
			const userData = result.Data.data;
			const userLanguage = userData.language;
			if (!userLanguage) {
				authService.updateLanguage(selectedLang, userData.id);
			} else {
				i18next.changeLanguage(userLanguage);
				setItem('lng', userLanguage);
			}
			dispatch(loginSuccess(result.Data));
			dispatch(setCompanies(result.Data.data?.companies));
			const company = result.Data.data?.companies?.find((com) => com.userStatus === 'active');
			company && dispatch(updateUserAttributes(company));
		} else {
			dispatch(failure());
		}
	};

	function loginRequest(email) {
		return { type: authConstants.LOGIN_REQUEST, email };
	}
	function loginSuccess(result) {
		return { type: authConstants.LOGIN_SUCCESS, result };
	}
	function failure() {
		return { type: authConstants.LOGIN_FAILURE };
	}
}

export function googleLogin(response) {
	return async (dispatch) => {
		dispatch(googleLoginRequest());
		let result = await authService.googleLogin(response.access_token);
		if (result.OK) {
			dispatch(googleLoginSuccess(result.Data));
			dispatch(setCompanies(result.Data.data?.companies));
			const company = result.Data.data?.companies?.find((com) => com.userStatus === 'active');
			company && dispatch(updateUserAttributes(company));
		} else {
			dispatch(googleLoginFailure());
		}
	};

	function googleLoginRequest() {
		return { type: authConstants.LOGIN_GOOGLE_REQUEST };
	}
	function googleLoginSuccess(result) {
		return { type: authConstants.LOGIN_GOOGLE_SUCCESS, result };
	}
	function googleLoginFailure() {
		return { type: authConstants.LOGIN_GOOGLE_FAILURE, message: 'error.user_doesnt_exists_google' };
	}
}

export function appleLogin(response) {
	return async (dispatch) => {
		dispatch(appleLoginRequest());
		let result = await authService.appleLogin(response.authorization.id_token);
		if (result.OK) {
			dispatch(appleLoginSuccess(result.Data));
			dispatch(setCompanies(result.Data.data?.companies));
			const company = result.Data.data?.companies?.find((com) => com.userStatus === 'active');
			company && dispatch(updateUserAttributes(company));
		} else {
			dispatch(appleLoginFailure());
		}
	};

	function appleLoginRequest() {
		return { type: authConstants.LOGIN_APPLE_REQUEST };
	}
	function appleLoginSuccess(result) {
		return { type: authConstants.LOGIN_APPLE_SUCCESS, result };
	}
	function appleLoginFailure() {
		return { type: authConstants.LOGIN_APPLE_FAILURE, message: 'error.user_doesnt_exists_apple' };
	}
}

export function signUp(userName, userEmail, password, passwordRepeat, language, promoCode) {
	return async (dispatch) => {
		dispatch(signUpRequest());
		let result = await authService.signUp(userName, userEmail, password, passwordRepeat, language, promoCode);
		if (result.OK) {
			dispatch(signUpSuccess(result.Data));
		} else {
			dispatch(signUpFailure(result.ErrorText));
		}
	};

	function signUpRequest() {
		return { type: authConstants.SIGNUP_REQUEST };
	}
	function signUpSuccess(result) {
		return { type: authConstants.SIGNUP_SUCCESS, result };
	}
	function signUpFailure(message) {
		return { type: authConstants.SIGNUP_FAILURE, message };
	}
}

export function signUpGoogle(response, promoCode) {
	return async (dispatch) => {
		dispatch(signUpGoogleRequest());
		let result = await authService.signUpGoogle(response.access_token, promoCode);
		if (result.OK) {
			dispatch(signUpGoogleSuccess(result.message, response));
			dispatch(googleLogin(response));
			refreshTokenSetup(response);
		} else {
			dispatch(signUpGoogleFailure(result.ErrorText));
		}
	};

	function signUpGoogleRequest() {
		return { type: authConstants.SIGNUP_GOOGLE_REQUEST };
	}
	function signUpGoogleSuccess(message, response) {
		return { type: authConstants.SIGNUP_GOOGLE_SUCCESS, message, response };
	}
	function signUpGoogleFailure(message) {
		return { type: authConstants.SIGNUP_GOOGLE_FAILURE, message };
	}
}

export function signUpApple(response, promoCode) {
	return async (dispatch) => {
		dispatch(signUpAppleRequest());
		let result = await authService.signUpApple(response.authorization.id_token, response.user, promoCode);
		if (result.OK) {
			dispatch(signUpAppleSuccess(result.message, response));
		} else {
			dispatch(signUpAppleFailure(result.ErrorText));
		}
	};

	function signUpAppleRequest() {
		return { type: authConstants.SIGNUP_APPLE_REQUEST };
	}
	function signUpAppleSuccess(message, response) {
		return { type: authConstants.SIGNUP_APPLE_SUCCESS, message, response };
	}
	function signUpAppleFailure(message) {
		return { type: authConstants.SIGNUP_APPLE_FAILURE, message };
	}
}

export function deleteUserAccount() {
	return async (dispatch) => {
		dispatch(deleteUserAccountRequest());
		let result = await authService.deleteUserAccount();
		if (result.OK) {
			dispatch(deleteUserAccountSuccess(result.responseData));
		} else {
			dispatch(deleteUserAccountFailure(result.message));
		}
	};

	function deleteUserAccountRequest() {
		return { type: authConstants.DELETE_USER_ACCOUNT_REQUEST };
	}
	function deleteUserAccountSuccess(result) {
		return { type: authConstants.DELETE_USER_ACCOUNT_SUCCESS, result };
	}
	function deleteUserAccountFailure(message) {
		return {
			type: authConstants.DELETE_USER_ACCOUNT_FAILURE,
			message: message
		};
	}
}

export function resetSignUpSuccessfully() {
	return { type: authConstants.RESET_SIGNUP_SUCCESS };
}

export function logout() {
	return { type: authConstants.LOGOUT_SUCCESS };
}

export function resetPassword(email) {
	return async (dispatch) => {
		dispatch(request());

		let result = await authService.resetPassword(email);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: authConstants.RESET_PASSWORD_REQUEST };
	}
	function success(result) {
		return { type: authConstants.RESET_PASSWORD_SUCCESS, result };
	}
	function failure() {
		return { type: authConstants.RESET_PASSWORD_FAILURE };
	}
}

export function resetPasswordState() {
	return { type: authConstants.RESET_PASSWORD_STATE };
}

export function verifyAccount(userHash) {
	return async (dispatch) => {
		dispatch(request());

		let result = await authService.verifyAccount(userHash);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure(result.ErrorText));
		}
	};

	function request() {
		return { type: authConstants.VERIFY_ACCOUNT_REQUEST };
	}
	function success(result) {
		return { type: authConstants.VERIFY_ACCOUNT_SUCCESS, result };
	}
	function failure(message) {
		return { type: authConstants.VERIFY_ACCOUNT_FAILURE, message };
	}
}

export function updateUserAttributes(data, userData, companyChanged) {
	return { type: workerConstants.UPDATE_USER_ATTRIBUTES, data, userData, companyChanged };
}

export function getTermsFile() {
	return async (dispatch) => {
		dispatch(request());

		let result = await authService.getTermsFile();
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: authConstants.GET_TERMS_FILE_REQUEST };
	}
	function success(result) {
		return { type: authConstants.GET_TERMS_FILE_SUCCESS, file: result.file, version: result.version };
	}
	function failure() {
		return { type: authConstants.GET_TERMS_FILE_FAILURE };
	}
}

export function acceptTerms(version) {
	return async (dispatch) => {
		dispatch(request());

		let result = await authService.acceptTerms(version);
		if (result.OK) {
			dispatch(success());
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: authConstants.ACCEPT_TERMS_REQUEST };
	}
	function success() {
		return { type: authConstants.ACCEPT_TERMS_SUCCESS };
	}
	function failure() {
		return { type: authConstants.ACCEPT_TERMS_FAILURE };
	}
}

export function upgradeRole() {
	return async (dispatch) => {
		dispatch(request());

		let result = await authService.upgradeRole();
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: authConstants.UPGRADE_ROLE_REQUEST };
	}
	function success(result) {
		return { type: authConstants.UPGRADE_ROLE_SUCCESS, result };
	}
	function failure() {
		return { type: authConstants.UPGRADE_ROLE_FAILURE };
	}
}
