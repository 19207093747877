import { workerConstants } from '../constants';

export function workerReducer(
	state = {
		workerRegister: false,
		workers: [],
		checkUserEmailErrorMessage: '',
		checkUserEmailSuccessMessage: '',
		attachUserToCompany: false,
		attachUserToCompanyFailed: false,
		attachUserToCompanyMessageFailed: '',
		notes: [],
		notesMessageFailed: ''
	},
	action
) {
	switch (action.type) {
		case workerConstants.REGISTER_WORKER_REQUEST:
			return {
				...state,
				workerRegister: false
			};
		case workerConstants.REGISTER_WORKER_SUCCESS:
			return {
				...state,
				workerRegister: true
			};
		case workerConstants.REGISTER_WORKER_FAILURE:
			return {
				...state,
				workerRegister: false
			};
		case workerConstants.REGISTER_WORKER_RESET_STATE:
			return {
				...state,
				workerRegister: false
			};
		// getWorkers
		case workerConstants.GET_WORKERS_REQUEST:
			return {
				...state,
				gettingWorkers: true,
				workers: [],
				automaticRefreshWorkingTime: false
			};
		case workerConstants.GET_WORKERS_SUCCESS:
			return {
				...state,
				gettingWorkers: false,
				workers: action.result?.map((worker) => {
					return {
						...worker,
						value: worker.id,
						label: worker.userName,
						services:
							worker.services?.map((service) => {
								return {
									id: service.id,
									value: service.id,
									label: service.name,
									timeRequired: service.timeRequired
								};
							}) || []
					};
				})
			};
		case workerConstants.GET_WORKERS_FAILURE:
			return {
				...state,
				gettingWorkers: false,
				workers: []
			};
		// addWorker
		case workerConstants.ADD_WORKER_REQUEST:
			return {
				...state,
				addingWorker: true,
				workerAdded: false,
				workerAddFailed: false
			};
		case workerConstants.ADD_WORKER_SUCCESS:
			return {
				...state,
				addingWorker: false,
				workerAdded: true
			};
		case workerConstants.ADD_WORKER_FAILURE:
			return {
				...state,
				addingWorker: false,
				workerAddFailed: true
			};
		// editWorker
		case workerConstants.EDIT_WORKER_REQUEST:
			return {
				...state,
				editingWorker: true,
				workerEdited: false,
				workerEditFailed: false
			};
		case workerConstants.EDIT_WORKER_SUCCESS:
			return {
				...state,
				editingWorker: false,
				workerEdited: true
			};
		case workerConstants.EDIT_WORKER_FAILURE:
			return {
				...state,
				editingWorker: false,
				workerEditFailed: true
			};
		// deleteWorker
		case workerConstants.DELETE_WORKER_REQUEST:
			return {
				...state,
				workerDeleted: false,
				workerDeleteFailed: false
			};
		case workerConstants.DELETE_WORKER_SUCCESS:
			return {
				...state,
				workerDeleted: true
			};
		case workerConstants.DELETE_WORKER_FAILURE:
			return {
				...state,
				workerDeleteFailed: true
			};
		//resetWorkerState
		case workerConstants.RESET_WORKER_STATE:
			return {
				...state,
				workerAdded: false,
				workerEdited: false,
				workerDeleted: false
			};
		//checkUserEmail
		case workerConstants.CHECK_USER_EMAIL_REQUEST:
			return {
				...state,
				checkUserEmail: false,
				checkUserEmailSuccessMessage: '',
				checkUserEmailErrorMessage: ''
			};
		case workerConstants.CHECK_USER_EMAIL_SUCCESS:
			return {
				...state,
				checkUserEmail: true,
				checkUserEmailSuccessMessage: action.message,
				checkUserEmailErrorMessage: ''
			};
		case workerConstants.CHECK_USER_EMAIL_FAILURE:
			return {
				...state,
				checkUserEmail: false,
				checkUserEmailSuccessMessage: '',
				checkUserEmailErrorMessage: action.message
			};
		case workerConstants.SEND_USER_INVITE_REQUEST:
			return {
				...state,
				userInviteSent: false,
				userInviteFailed: false
			};
		case workerConstants.SEND_USER_INVITE_SUCCESS:
			return {
				...state,
				userInviteSent: true,
				userInviteFailed: false
			};
		case workerConstants.SEND_USER_INVITE_FAILURE:
			return {
				...state,
				userInviteSent: false,
				userInviteFailed: true
			};
		case workerConstants.ATTACH_USER_TO_COMPANY_REQUEST:
			return {
				...state,
				attachUserToCompany: false,
				attachUserToCompanyFailed: false,
				attachUserToCompanyMessageFailed: ''
			};
		case workerConstants.ATTACH_USER_TO_COMPANY_SUCCESS:
			return {
				...state,
				attachUserToCompany: true,
				attachUserToCompanyFailed: false,
				attachUserToCompanyMessageFailed: ''
			};
		case workerConstants.ATTACH_USER_TO_COMPANY_FAILURE:
			return {
				...state,
				attachUserToCompany: false,
				attachUserToCompanyFailed: true,
				attachUserToCompanyMessageFailed: action.message
			};
		case workerConstants.AUTOMATIC_REFRESH_WORKING_TIME_REQUEST:
			return {
				...state,
				automaticRefreshWorkingTime: false,
				automaticRefreshWorkingTimeMessageFailed: ''
			};
		case workerConstants.AUTOMATIC_REFRESH_WORKING_TIME_SUCCESS:
			return {
				...state,
				automaticRefreshWorkingTime: true,
				workers: state.workers.map((worker) => {
					if (worker.id === action.result.id) {
						worker.automaticRefreshWorkingTime = action.result.automaticRefreshWorkingTime;
					}
					return worker;
				})
			};
		case workerConstants.AUTOMATIC_REFRESH_WORKING_TIME_FAILURE:
			return {
				...state,
				automaticRefreshWorkingTime: false,
				automaticRefreshWorkingTimeMessageFailed: action.message
			};
		// getNotes
		case workerConstants.GET_NOTES_REQUEST:
			return {
				...state,
				notesLoading: true,
				notesMessageFailed: ''
			};
		case workerConstants.GET_NOTES_SUCCESS:
			return {
				...state,
				notesLoading: false,
				notes: action.result[0].notes
			};
		case workerConstants.GET_NOTES_FAILURE:
			return {
				...state,
				notesLoading: false
			};
		// setNotes
		case workerConstants.SET_NOTES_REQUEST:
			return {
				...state,
				notesLoading: true,
				notesMessageFailed: ''
			};
		case workerConstants.SET_NOTES_SUCCESS:
			return {
				...state,
				notesLoading: false
			};
		case workerConstants.SET_NOTES_FAILURE:
			return {
				...state,
				notesLoading: false,
				notesMessageFailed: 'something_went_wrong'
			};
		// resetNotes
		case workerConstants.RESET_NOTES:
			return {
				...state,
				notes: []
			};
		default:
			return state;
	}
}
