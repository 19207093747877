import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Modal, Form } from 'react-bootstrap';

const CustomModal = ({ t, showModal, title, content, confirmationText, handleSubmit, handleClose }) => {
	async function onHandleSubmit(event) {
		event.preventDefault();
		handleSubmit();
	}

	return (
		<Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
			<Modal.Header>
				<div className="d-flex justify-content-start align-items-center">
					<h4 className="modal-title">{title}</h4>
				</div>
				<button type="button" className="close" onClick={handleClose}>
					<span aria-hidden="true">×</span>
					<span className="sr-only">Close</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate onSubmit={onHandleSubmit}>
					<p className="text-truncate">{content}</p>
					<div className="category-page__modal-footer">
						<Button variant="success" data-dismiss="modal" type="submit">
							{confirmationText}
						</Button>
						<Button variant="danger" data-dismiss="modal" type="close" onClick={handleClose}>
							{t('label.cancel')}
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default withNamespaces()(CustomModal);
