import axios from 'axios';
import { createOKResponse, createErrorResponse } from './responses';
import config from './config';
import { getToken } from './authService';

export async function getPromoCodes(companyId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/promo-code/get-all?companyId=${companyId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.get_promo_codes_failed');
	}
}

export async function addPromoCode(data) {
	try {
		const tokenResponse = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: tokenResponse
			},
			url: `${config.url}/promo-code/add`,
			data
		});

		return createOKResponse(response.data.responseCode, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function editPromoCode(data) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/promo-code/edit`,
			data
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.edit_promo_code_error');
	}
}

export async function deletePromoCode(promoCodeId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/promo-code/delete/${promoCodeId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.delete_promo_code_error');
	}
}
