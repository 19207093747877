import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';

const DeleteModal = (props) => {
	const { title, content, showModal, handleClose, t } = props;

	function handleDelete() {
		props.handleDelete && props.handleDelete();
		handleClose();
	}

	return (
		<Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
			<Modal.Header>
				<div className="d-flex justify-content-start align-items-center">
					<h4 className="modal-title">{title}</h4>
				</div>
				<button type="button" className="close" onClick={handleClose}>
					<span aria-hidden="true">×</span>
					<span className="sr-only">Close</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate>
					<p>{content}</p>
					<div className="category-page__modal-footer">
						<Button variant="success" data-dismiss="modal" type="close" onClick={handleClose}>
							{t('label.close')}
						</Button>
						<Button variant="danger" data-dismiss="modal" type="submit" onClick={handleDelete}>
							{t('label.delete')}
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default withNamespaces()(DeleteModal);
