import React from 'react';

const CategoryPage = React.lazy(() => import('./views/pages/category/CategoryPage'));
const CompanyPage = React.lazy(() => import('./views/pages/company/CompanyPage'));
const WorkerPage = React.lazy(() => import('./views/pages/worker/WorkerPage'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Dashboard = React.lazy(() => import('./views/pages/dashboard/DashboardPage'));
const ReviewPage = React.lazy(() => import('./views/pages/reviews/ReviewPage'));
const ClassificationPage = React.lazy(() => import('./views/pages/configurations/ClassificationPage'));
const Statistics = React.lazy(() => import('./views/pages/statistics/StatisticsPage'));
const Service = React.lazy(() => import('./views/pages/services/ServicePage'));
const TimesheetPage = React.lazy(() => import('./views/pages/timesheet/TimesheetPage'));
const PaymentHistoryPage = React.lazy(() => import('./views/pages/paymentHistory/PaymentHistoryPage'));
const Appointments = React.lazy(() => import('./views/pages/appointments/Appointments'));
const ContactSupport = React.lazy(() => import('./views/pages/contactSupport/contactSupport'));
const HelpPage = React.lazy(() => import('./views/pages/help/HelpPage'));
const SubscriptionPlan = React.lazy(() => import('./views/pages/subscription/SubscriptionPlan'));
const PromoCodePage = React.lazy(() => import('./views/pages/promoCode/PromoCodePage'));

export function getRoutes(roleName, t) {
	let routes = [];

	if (roleName === 'headAdmin') {
		routes = [
			{
				path: '/login',
				exact: true,
				name: t('label.login'),
				component: Login
			},
			{
				exact: true,
				path: '/',
				name: t('label.categories'),
				component: CategoryPage
			},
			{
				path: '/categories',
				name: t('label.categories'),
				component: CategoryPage
			},
			{
				path: '/dashboard',
				name: t('label.dashboard'),
				component: Dashboard
			},
			{ path: '/worker', name: t('label.employees'), component: WorkerPage },
			{ path: '/reviews', name: t('label.reviews'), component: ReviewPage },
			{
				path: '/company',
				name: t('label.stores'),
				component: CompanyPage
			},
			{ path: '/timesheet', name: t('label.timesheet'), component: TimesheetPage },
			{ path: '/reviews', name: t('label.reviews'), component: ReviewPage },
			{
				path: '/classifications',
				name: t('label.classifications'),
				component: ClassificationPage
			},

			{
				path: '/statistics',
				name: t('label.statistics'),
				component: Statistics
			},
			{
				path: '/payment-history',
				name: t('label.payment_history'),
				component: PaymentHistoryPage
			},
			{
				path: '/appointments',
				name: t('label.appointments'),
				component: Appointments
			},
			{ path: '/services', name: t('label.services'), component: Service },
			{
				path: '/help',
				name: t('label.help'),
				component: HelpPage
			},
			{
				path: '/subscription-plan',
				name: t('label.subscription_plan'),
				component: SubscriptionPlan
			},
			{
				path: '/promo-codes',
				name: t('label.promo_codes'),
				component: PromoCodePage
			}
		];
	} else if (roleName === 'companyAdmin') {
		routes = [
			{
				path: '/login',
				exact: true,
				name: t('label.login'),
				component: Login
			},
			{ path: '/', exact: true, name: t('label.timesheet'), component: TimesheetPage },
			{
				path: '/profile',
				name: t('label.profile'),
				component: Dashboard
			},
			{
				path: '/company',
				name: t('label.stores'),
				component: CompanyPage
			},
			{
				path: '/profile',
				name: t('label.profile'),
				component: Dashboard
			},
			{ path: '/worker', name: t('label.employees'), component: WorkerPage },
			{ path: '/reviews', name: t('label.reviews'), component: ReviewPage },
			{ path: '/services', name: t('label.services'), component: Service },
			{ path: '/timesheet', name: t('label.timesheet'), component: TimesheetPage },
			{
				path: '/statistics',
				name: t('label.statistics'),
				component: Statistics
			},
			{
				path: '/payment-history',
				name: t('label.payment_history'),
				component: PaymentHistoryPage
			},
			{
				path: '/appointments',
				name: t('label.appointments'),
				component: Appointments
			},
			{
				path: '/contact-support',
				name: t('label.contact_support'),
				component: ContactSupport
			},
			{
				path: '/help',
				name: t('label.help'),
				component: HelpPage
			},
			{
				path: '/subscription-plan',
				name: t('label.subscription_plan'),
				component: SubscriptionPlan
			},
			{
				path: '/promo-codes',
				name: t('label.promo_codes'),
				component: PromoCodePage
			}
		];
	}
	if (roleName === 'worker') {
		routes = [
			{
				path: '/login',
				exact: true,
				name: t('label.login'),
				component: Login
			},
			{
				path: '/profile',
				name: t('label.profile'),
				component: Dashboard
			},
			{ path: '/', exact: true, name: t('label.timesheet'), component: TimesheetPage },
			{
				path: '/profile',
				name: t('label.profile'),
				component: Dashboard
			},
			{ path: '/reviews', name: t('label.reviews'), component: ReviewPage },
			{ path: '/services', name: t('label.services'), component: Service },
			{ path: '/timesheet', name: t('label.timesheet'), component: TimesheetPage },
			{
				path: '/payment-history',
				name: t('label.payment_history'),
				component: PaymentHistoryPage
			},
			{
				path: '/appointments',
				name: t('label.appointments'),
				component: Appointments
			},
			{
				path: '/contact-support',
				name: t('label.contact_support'),
				component: ContactSupport
			},
			{
				path: '/help',
				name: t('label.help'),
				component: HelpPage
			}
		];
	}

	return routes;
}
