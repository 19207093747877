import axios from 'axios';
import { createOKResponse, createErrorResponse } from './responses';
import config from './config';
import { getToken } from './authService';

export async function getAllWorkerAppointments(
	workerId,
	companyId,
	date,
	timeStatus = 'future',
	status,
	currentPage = 1,
	sort = 'DESC',
	itemsPerPage = 100
) {
	try {
		const tokenResponse = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				'Content-type': 'application/json',
				Authorization: tokenResponse
			},
			url: `${config.url}/appointment/get-appointment?workerId=${workerId}&companyId=${companyId}&date=${date}&timeStatus=${timeStatus}&status=${status}&sort=${sort}&itemsPerPage=${itemsPerPage}&page=${currentPage}`
		});
		return createOKResponse(response.data.responseCode, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function addAppointment(data) {
	try {
		const tokenResponse = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: tokenResponse
			},
			url: `${config.url}/appointment/add`,
			data
		});
		return createOKResponse(response.data.responseCode, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function deleteAppointment(appointmentId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/appointment/delete/${appointmentId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.delete_appointment_error');
	}
}

export async function editAppointment(data) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/appointment/edit`,
			data: {
				...data,
				manuallyEntered: true
			}
		});

		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.edit_appointment_error');
	}
}

export async function approveAppointment(appointmentId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/appointment/approve`,
			data: {
				appointmentId
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function declineAppointment(appointmentId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/appointment/decline`,
			data: {
				appointmentId
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function addBreak(workerId, companyId, breakDay, breakStart, breakEnd) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/appointment/addBreak`,
			data: {
				breaks: [
					{
						workerId,
						companyId,
						breakDay,
						breakStart,
						breakEnd
					}
				]
			}
		});
		return createOKResponse(response.data.responseCode, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}
