import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { withNamespaces } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { PHOTO_CAMERA, readFileUrl } from '../../helpers';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const MAX_LOGO_FILE_SIZE = 1024000; // size in bytes
const defaultCrop = {
	unit: '%',
	width: 30,
	aspect: 16 / 9
};

let fileUrl;
let imageRef;

const CropImage = (props) => {
	const { t, photo } = props;
	const [src, setSrc] = useState(photo);
	const [crop, setCrop] = useState(defaultCrop);
	const [imageErrorMessage, setImageErrorMessage] = useState('');

	const onSelectFile = (files) => {
		if (files.length > 0) {
			const file = files[0];
			if (file.size > MAX_LOGO_FILE_SIZE) {
				setImageErrorMessage('error.image_too_large');
			} else {
				const reader = new FileReader();
				reader.addEventListener('load', () => setSrc(reader.result));
				reader.readAsDataURL(file);
			}
		}
	};

	// If you setState the crop in here you should return false.
	const onImageLoaded = (image) => {
		imageRef = image;
	};

	const onCropComplete = (crop) => {
		makeClientCrop(crop);
	};

	const onCropChange = (crop, percentCrop) => {
		setCrop(crop);
	};

	async function makeClientCrop(crop) {
		if (imageRef && crop.width && crop.height) {
			const croppedImg = await getCroppedImg(imageRef, crop, 'newFile.jpeg');
			setImageErrorMessage('');
			if (props.setCroppedImg) {
				let blob = await fetch(croppedImg).then((r) => r.blob());
				readFileUrl(blob, (result) => {
					props.setCroppedImg(result);
				});
			}
		}
	}

	function getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(fileUrl);
				fileUrl = window.URL.createObjectURL(blob);
				resolve(fileUrl);
			}, 'image/jpeg');
		});
	}

	function removePhoto() {
		setImageErrorMessage('');
		setSrc('');
		setCrop(defaultCrop);
	}

	return (
		<div className="App">
			{src ? (
				<>
					<ReactCrop src={src} crop={crop} ruleOfThirds onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={onCropChange} />
					<div className="form-group">
						<Button variant="danger" onClick={removePhoto} className="textlink-big delete-photo">
							<FontAwesomeIcon icon={faTrash} />
						</Button>
					</div>
				</>
			) : (
				<>
					<Dropzone
						onDrop={onSelectFile}
						accept="image/*"
						className="inactive-drop-zone pointer"
						activeClassName="active-drop-zone"
						rejectClassName="reject-drop-zone">
						{({ getRootProps, getInputProps }) => (
							<section className="active-drop-zone-section">
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<div className="drop-zone-inner-wrapper">
										<svg src={PHOTO_CAMERA(40, 36)} />
										<div className="vt-sans drag-zone">{t('label.drag_and_drop_file')}</div>
										<div className="drop-zone-button">
											<Button variant="success">{t('label.choose_file')}</Button>
										</div>
									</div>
								</div>
							</section>
						)}
					</Dropzone>
					<div className="error-tip">{imageErrorMessage && <p className="error-text">{t(imageErrorMessage)}</p>}</div>
				</>
			)}
		</div>
	);
};

export default withNamespaces()(CropImage);
