import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TheContent, TheSidebar, TheHeader } from './index';
import { Redirect } from 'react-router-dom';
import { getLocation, base64toFile } from '../helpers';
//isNull
import { ChatComponent } from '../views/components';
import { Button, Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { logout, upgradeRole } from '../actions';
import { sideBarConstants } from '../constants';
import { sendError } from '../services/errorService';
//acceptTerms, getTermsFile

const TheLayout = ({ t }) => {
	const dispatch = useDispatch();
	const { companies } = useSelector((state) => state.companyReducer);
	const { infoBoxHidden } = useSelector((state) => state.changeState);
	// const { loggedIn, userAttributes, termsFile, termsAccepted, acceptTermsLoading, version, roleName } = useSelector(
	// 	(state) => state.authentication
	// );
	const { loggedIn, termsFile, roleName, upgradeRoleLoading, upgradeRoleFailed, userAttributes } = useSelector((state) => state.authentication);
	const [testBarVisible, setTestBarVisible] = useState(true);
	const fileRef = useRef();

	useEffect(() => {
		if (window.location.href.indexOf('test') !== -1 || window.location.href.indexOf('localhost') !== -1) {
			setTestBarVisible(true);
		} else {
			setTestBarVisible(false);
		}
	}, []);

	useEffect(() => {
		try {
			if (userAttributes?.role?.roleName !== 'headAdmin' && loggedIn) {
				if (companies?.length === 0 && userAttributes.companies?.length === 0) {
					// dispatch({ type: sideBarConstants.INFO_BOX_OPEN, open: true });
					dispatch({ type: sideBarConstants.INFO_BOX_HIDDEN, hidden: false });
					dispatch({ type: sideBarConstants.CHANGE_STEP, stepId: 1, finished: true });
				} else {
					if (!infoBoxHidden) {
						dispatch({ type: sideBarConstants.CHANGE_STEP, stepId: 1, finished: true });
						dispatch({ type: sideBarConstants.CHANGE_STEP, stepId: 2, finished: true });
						// dispatch({ type: sideBarConstants.INFO_BOX_OPEN, open: true });
						dispatch({ type: sideBarConstants.INFO_BOX_HIDDEN, hidden: false });
					}
				}
			}
		} catch (error) {
			sendError('TheLayout - useEffect() - dispatch, companies, userAttributes - ', error);
		}
		//eslint-disable-next-line
	}, [dispatch, companies, userAttributes]);

	// useEffect(() => {
	// 	if (userAttributes && !userAttributes.termsOfUseAccepted && loggedIn) {
	// 		dispatch(getTermsFile());
	// 	}
	// }, [userAttributes, dispatch, loggedIn]);

	useEffect(() => {
		if (termsFile && fileRef?.current) {
			fileRef.current.innerHTML = base64toFile(termsFile);
		}
	}, [termsFile]);

	useEffect(() => {
		if (upgradeRoleFailed) {
			dispatch(logout());
		}
	}, [dispatch, upgradeRoleFailed]);

	function handleClose() {
		dispatch(logout());
	}

	if (!loggedIn && !getLocation().includes('set-password') && !getLocation().includes('activate')) {
		return <Redirect to="/login" />;
	}
	return (
		<div className="c-app c-default-layout">
			<TheSidebar />
			<div className="c-wrapper">
				<TheHeader />
				<div className="c-body">
					{roleName && roleName === 'client' ? (
						<div className="card">
							<div className="card-body">{t('label.you_do_not_currently_have_access')}</div>
						</div>
					) : (
						<TheContent />
					)}
				</div>
			</div>
			<ChatComponent />
			{testBarVisible && <div className="test-bar">{t('label.test_environment')}</div>}
			<Modal
				className="update-role-modal"
				show={loggedIn && roleName && roleName === 'client'}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="sm">
				<Modal.Header>
					<div className="d-flex justify-content-start align-items-center">
						<h4 className="modal-title">{t('label.do_you_want_to_register_your_company')}</h4>
					</div>
					<button type="button" className="close" onClick={handleClose}>
						<span aria-hidden="true">×</span>
						<span className="sr-only">Close</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-footer update-role">
						<Button
							variant="success"
							data-dismiss="modal"
							type="submit"
							disabled={upgradeRoleLoading}
							onClick={() => {
								dispatch(upgradeRole());
							}}>
							{t('label.accept')}
						</Button>
						<Button variant="danger" data-dismiss="modal" type="close" onClick={handleClose}>
							{t('label.decline')}
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			{/* <Modal
				show={(isNull(userAttributes?.termsOfUseAccepted) || !userAttributes?.termsOfUseAccepted) && !termsAccepted && termsFile}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg">
				<Modal.Header >
					<div className="d-flex justify-content-start align-items-center">
						<h4 className="modal-title">{t('label.please_read_and_accept_the_terms')}</h4>
					</div>
					<button type="button" className="close" onClick={handleClose}>
						<span aria-hidden="true">×</span>
						<span className="sr-only">Close</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					<>
						<div className="category-page__modal-body">
							<div className="terms-of-use" ref={fileRef}></div>
						</div>
						<div className="category-page__modal-footer">
							<Button
								variant="success"
								data-dismiss="modal"
								type="submit"
								disabled={acceptTermsLoading}
								onClick={() => {
									dispatch(acceptTerms(version));
								}}>
								{t('label.accept')}
							</Button>
							<Button variant="danger" data-dismiss="modal" type="close" onClick={handleClose}>
								{t('label.decline')}
							</Button>
						</div>
					</>
				</Modal.Body>
			</Modal> */}
		</div>
	);
};

export default withNamespaces()(TheLayout);
