import React from 'react';
import ContentLoader from 'react-content-loader';

const TableLoader = () => (
	<ContentLoader speed={2} width={400} height={160} viewBox="0 0 400 160" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
		<rect x="0" y="16" rx="8" ry="8" width="190" height="14" />
		<rect x="240" y="16" rx="8" ry="8" width="130" height="14" />
		<rect x="0" y="62" rx="8" ry="8" width="190" height="14" />
		<rect x="240" y="62" rx="8" ry="8" width="130" height="14" />
		<rect x="0" y="108" rx="8" ry="8" width="190" height="14" />
		<rect x="240" y="108" rx="8" ry="8" width="130" height="14" />
	</ContentLoader>
);

export default TableLoader;
