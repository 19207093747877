import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppleSignin from 'react-apple-signin-auth';
import { withNamespaces } from 'react-i18next';
import { globalConstants } from '../../constants';
import { appleLogin, signUpApple } from '../../actions';
import appleLogo from '../../assets/icons/apple-logo.svg';
import { sendError } from '../../services/errorService';
import { classnames } from '../../helpers';

const AppleLoginComponent = ({ t, label, type, promoCode, ...rest }) => {
	const dispatch = useDispatch();
	const { appleSignUpResponse } = useSelector((state) => state.authentication);
	const buttonClassName = classnames(type === globalConstants.LOGIN ? 'login-form__apple-button' : 'register-form__apple-button');
	const buttonWrapperClassName = classnames(type === globalConstants.LOGIN ? 'login-form__apple-wrapper' : 'register-form__apple-wrapper');

	useEffect(() => {
		if (appleSignUpResponse) {
			dispatch(appleLogin(appleSignUpResponse));
		}
	}, [dispatch, appleSignUpResponse]);

	const onSuccess = (res) => {
		if (type === globalConstants.LOGIN) {
			dispatch(appleLogin(res));
		} else {
			dispatch(signUpApple(res, promoCode));
		}
	};

	const onError = (err) => {
		sendError('AppleLoginComponent - onError() - ', err.error);
	};

	return (
		<AppleSignin
			/** Auth options passed to AppleID.auth.init() */
			authOptions={{
				/** Client ID - eg: 'com.example.com' */
				clientId: globalConstants.APPLE_CLIENT_ID,
				/** Requested scopes, seperated by spaces - eg: 'email name' */
				scope: 'name email',
				/** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
				redirectURI: globalConstants.APPLE_REDIRECT_URI,
				/** State string that is returned with the apple response */
				state: 'state',
				/** Nonce */
				nonce: 'nonce',
				/** Uses popup auth instead of redirection */
				usePopup: true
			}} // REQUIRED
			/** General props */
			uiType="dark"
			/** className */
			className="apple-auth-btn"
			/** Removes default style tag */
			noDefaultStyle={false}
			/** Allows to change the button's children, eg: for changing the button text */
			buttonExtraChildren="Continue with Apple"
			/** Extra controlling props */
			/** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
			onSuccess={onSuccess} // default = undefined
			/** Called upon signin error */
			onError={onError} // default = undefined
			/** Skips loading the apple script if true */
			skipScript={false} // default = undefined
			/** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
			render={(props) => (
				<div className={buttonWrapperClassName}>
					<button className={buttonClassName} {...props}>
						<img alt="apple" src={appleLogo} />
						{t(label)}
					</button>
				</div>
			)}
		/>
	);
};

export default withNamespaces()(AppleLoginComponent);
