export const promoCodeConstants = {
	GET_ALL_PROMO_CODES_REQUEST: 'GET_ALL_PROMO_CODES_REQUEST',
	GET_ALL_PROMO_CODES_SUCCESS: 'GET_ALL_PROMO_CODES_SUCCESS',
	GET_ALL_PROMO_CODES_FAILURE: 'GET_ALL_PROMO_CODES_FAILURE',

	ADD_PROMO_CODE_REQUEST: 'ADD_PROMO_CODE_REQUEST',
	ADD_PROMO_CODE_SUCCESS: 'ADD_PROMO_CODE_SUCCESS',
	ADD_PROMO_CODE_FAILURE: 'ADD_PROMO_CODE_FAILURE',

	EDIT_PROMO_CODE_REQUEST: 'EDIT_PROMO_CODE_REQUEST',
	EDIT_PROMO_CODE_SUCCESS: 'EDIT_PROMO_CODE_SUCCESS',
	EDIT_PROMO_CODE_FAILURE: 'EDIT_PROMO_CODE_FAILURE',

	DELETE_PROMO_CODE_REQUEST: 'DELETE_PROMO_CODE_REQUEST',
	DELETE_PROMO_CODE_SUCCESS: 'DELETE_PROMO_CODE_SUCCESS',
	DELETE_PROMO_CODE_FALIURE: 'DELETE_PROMO_CODE_FALIURE'
};
