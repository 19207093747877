import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../src/reducers';
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import { logout } from './actions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

const myErrorHandler = (error) => {
	if (error && error.message === 'Session expired') {
		return logoutThunk;
	}
};

export const logoutThunk = async (dispatch) => {
	dispatch(logout());
};

const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({ onError: myErrorHandler });

export function configureStore() {
	// TODO: use this to enable dev tools
	const store = createStore(rootReducer, composeEnhancers(applyMiddleware(errorHandlerMiddleware, thunk)));
	return store;
}
