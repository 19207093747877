import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { getAllMessages, getAllMessagesPerUserId, sendMessage, removeAllMessagesPerUser, setChatVisible, setSelectedUser } from '../../actions';
import { sendError } from '../../services/errorService';
import moment from 'moment';
import avatar from '../../assets/images/avatar.png';
import OutsideClickHandler from 'react-outside-click-handler';
import { colorConstants, globalConstants } from '../../constants';
import { groupArrays } from '../../helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faComments } from '@fortawesome/free-solid-svg-icons';

let currentPage = 1;
const ChatComponent = ({ t }) => {
	const dispatch = useDispatch();
	const [input, setInput] = useState('');
	const { userAttributes } = useSelector((state) => state.authentication);
	const { chatVisible, messages, messagesPerUser, selectedUser, numberOfUserMessagesPerUser, totalNumberOfUserMessagesPerUser } = useSelector(
		(state) => {
			return state.chatReducer;
		}
	);
	const contentRef = useRef();
	const chatButtonRef = useRef();

	const sendMessageToService = useCallback(() => {
		if (input !== '') {
			dispatch(sendMessage(selectedUser.userId, input, userAttributes.id));
			setInput('');
		}
	}, [dispatch, input, selectedUser, userAttributes]);

	const handleUserKeyPress = useCallback(
		(event) => {
			const { keyCode } = event;
			if (keyCode === 13) {
				sendMessageToService();
			}
		},
		[sendMessageToService]
	);

	useEffect(() => {
		window.addEventListener('keydown', handleUserKeyPress);
		return () => {
			window.removeEventListener('keydown', handleUserKeyPress);
		};
	}, [handleUserKeyPress]);

	useEffect(() => {
		try {
			if (selectedUser) {
				dispatch(getAllMessagesPerUserId(selectedUser.userId, selectedUser.isRead || false, selectedUser.showEmptyDialog, currentPage));
			}
		} catch (error) {
			sendError(error.message, error.stack);
		}
	}, [dispatch, selectedUser]);

	useEffect(() => {
		if (chatVisible) {
			dispatch(getAllMessages());
		}
	}, [dispatch, chatVisible]);

	function getIsReadColor(isRead) {
		if (isRead) {
			return colorConstants.SILVER;
		}
		return colorConstants.BLACK;
	}

	function fetchMoreMessages() {
		try {
			currentPage = currentPage + 1;
			dispatch(getAllMessagesPerUserId(selectedUser.userId, selectedUser.isRead || false, selectedUser.showEmptyDialog, currentPage));
		} catch (error) {
			sendError(error.message, error.stack);
		}
	}

	return (
		<>
			{chatVisible && (
				<OutsideClickHandler
					onOutsideClick={(e) => {
						if (chatButtonRef && chatButtonRef.current && chatButtonRef.current.contains(e.target)) {
							return;
						} else {
							dispatch(setChatVisible(!chatVisible));
						}
					}}>
					<div className="chat-component">
						<div className="chat-component__header">
							{messagesPerUser && messagesPerUser.length > 0 && (
								<div
									className="chat-component__back-button"
									onClick={() => {
										dispatch(setSelectedUser(null));
										dispatch(removeAllMessagesPerUser());
										dispatch(getAllMessages());
										currentPage = 1;
									}}>
									<FontAwesomeIcon icon={faArrowCircleLeft} />
								</div>
							)}
							<div className="chat-component__chat-title">{t('label.chat')}</div>
						</div>
						{(messagesPerUser && messagesPerUser.length > 0) || (selectedUser && selectedUser.showEmptyDialog) ? (
							<>
								<div id="scrollableDiv" className="chat-component__scrollable-div" ref={contentRef}>
									<InfiniteScroll
										dataLength={messagesPerUser.length}
										next={fetchMoreMessages}
										className="chat-component__infinite-scroll"
										hasMore={numberOfUserMessagesPerUser !== totalNumberOfUserMessagesPerUser ? true : false}
										inverse={true}
										scrollableTarget="scrollableDiv">
										{messagesPerUser &&
											groupArrays(messagesPerUser).map((messageScope, messageScopeIndex) => (
												<div key={messageScopeIndex}>
													<div className="chat-component__current-date">
														{moment(messageScope.date).format('DD.MM.YYYY')}
													</div>
													{messageScope &&
														messageScope.messages &&
														messageScope.messages
															.map((item, index) => {
																if (item.userId !== userAttributes.id) {
																	return (
																		<div className="chat-component__message-user-wrapper" key={index}>
																			{selectedUser && (
																				<img
																					className="chat-component__user-img"
																					alt="user"
																					src={selectedUser.userImage || avatar}
																				/>
																			)}
																			<div className="chat-component__message-user-inner">
																				{selectedUser && (
																					<div className="chat-component__user-name">
																						{selectedUser.userName}
																					</div>
																				)}
																				<div className="chat-component__message chat-component__message--user">
																					<div>{item.message}</div>
																					<div>{moment(item.dateTime).format('HH:MM')}</div>
																				</div>
																			</div>
																		</div>
																	);
																}
																return (
																	<div className="chat-component__message" key={index}>
																		<div>{moment(item.dateTime).format('HH:MM')}</div>
																		<div>{item.message}</div>
																	</div>
																);
															})
															.reverse()}
												</div>
											))}
									</InfiniteScroll>
								</div>
								<div className="chat-component__footer">
									<input
										onChange={(e) => {
											setInput(e.target.value);
										}}
										value={input}
									/>
									<button
										onClick={(e) => {
											sendMessageToService();
										}}>
										{t('label.send')}
									</button>
								</div>
							</>
						) : (
							<div className="chat-component__content" ref={contentRef}>
								<div>
									{messages &&
										messages.map((m, index) => {
											const formattedTime = moment(m.lastMessageTime).format(globalConstants.DATE_TIME_FORMAT_SRB);
											return (
												<div
													className="chat-component__messages"
													key={index}
													onClick={() => {
														dispatch(setSelectedUser(m));
													}}>
													<img src={m.userImage || avatar} alt="user" className="chat-component__img" />
													<div className="chat-component__messages-inner">
														<div className="chat-component__left">
															<div>{m.userName}</div>
															<div
																className="chat-component__right"
																style={{
																	color: getIsReadColor(m.isRead)
																}}>
																{m.lastMessage}
															</div>
														</div>
														<div className="chat-component__right">
															<div
																style={{
																	color: getIsReadColor(m.isRead)
																}}>
																<div>{formattedTime.split(' ')[0]}</div>
																<div>{formattedTime.split(' ')[1]}</div>
															</div>
														</div>
													</div>
												</div>
											);
										})}
								</div>
							</div>
						)}
					</div>
				</OutsideClickHandler>
			)}
			<div
				className="chat-component__button"
				ref={chatButtonRef}
				onClick={() => {
					dispatch(setChatVisible(!chatVisible));
				}}>
				<FontAwesomeIcon icon={faComments} />
			</div>
		</>
	);
};

export default withNamespaces()(ChatComponent);
