import React from 'react';
import { withNamespaces } from 'react-i18next';

const FifthStepComponent = ({ t }) => {
	return (
		<div className="steps-wizzard__content">
			<span>{t('label.mobile_application_can_be_downloaded')}</span>
			<a href="https://mobile.bookall.rs/" target="blank">
				{t('label.google_play')}
			</a>
			<span>{t('label.or')}</span>
			<a href="https://mobile.bookall.rs/" target="blank">
				{t('label.app_store')}
			</a>
			<span>{t('label.and_start_using')}</span>
		</div>
	);
};

export default withNamespaces()(FifthStepComponent);
