import * as workerService from '../services/workerService';
import { workerConstants } from '../constants';
import { updateUserAttributes } from '.';
import { isArray } from 'underscore';
// import { errorAlert } from '../actions';

export function registerUser(newPassword, repeatPassword, userHash) {
	return async (dispatch) => {
		dispatch(request());
		let result = await workerService.registerUser(newPassword, repeatPassword, userHash);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: workerConstants.REGISTER_WORKER_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.REGISTER_WORKER_SUCCESS, result };
	}
	function failure() {
		return { type: workerConstants.REGISTER_WORKER_FAILURE };
	}
}

export function registerWorkerResetState() {
	return { type: workerConstants.REGISTER_WORKER_RESET_STATE };
}

export function setWorker(result) {
	return async (dispatch) => {
		if (result.OK) {
			dispatch(success());
		} else {
			dispatch(failure());
		}
	};

	function success() {
		return { type: workerConstants.SET_WORKER_SUCCESS, result: result.Data };
	}
	function failure() {
		return { type: workerConstants.SET_WORKER_FAILURE };
	}
}

export function getWorker(workerId) {
	return async (dispatch) => {
		dispatch(request());

		let result = await workerService.getWorker(workerId);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: workerConstants.GET_WORKER_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.GET_WORKER_SUCCESS, result };
	}
	function failure() {
		return { type: workerConstants.GET_WORKER_FAILURE };
	}
}

export function getWorkers(companyId, allWorkers, t) {
	return async (dispatch) => {
		dispatch(request());

		let result = await workerService.getWorkers(companyId);
		if (result.OK) {
			let responseDataCopy = [...result.Data];
			if (allWorkers) {
				responseDataCopy.unshift({
					id: 0,
					userName: t('label.all_workers')
				});
			}
			dispatch(success(responseDataCopy));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: workerConstants.GET_WORKERS_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.GET_WORKERS_SUCCESS, result };
	}
	function failure() {
		return { type: workerConstants.GET_WORKERS_FAILURE };
	}
}

export function addWorker(companyId, userName, userDescription, userEmail, userPhoneNumber, role, services, image, sex, dateOfBirth) {
	return async (dispatch) => {
		dispatch(request());

		let result = await workerService.addWorker(
			companyId,
			userName,
			userDescription,
			userEmail,
			userPhoneNumber,
			role,
			services,
			image,
			sex,
			dateOfBirth
		);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: workerConstants.ADD_WORKER_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.ADD_WORKER_SUCCESS, result };
	}
	function failure() {
		return { type: workerConstants.ADD_WORKER_FAILURE };
	}
}

export function editWorker(
	userId,
	companyId,
	userName,
	userDescription,
	userEmail,
	userPhoneNumber,
	role,
	services,
	image,
	autoGeneratePassword,
	currentPassword,
	newPassword,
	newPasswordRepeat,
	sex,
	dateOfBirth,
	dashboardPage
) {
	return async (dispatch) => {
		dispatch(request());

		let result = await workerService.editWorker(
			userId,
			companyId,
			userName,
			userDescription,
			userEmail,
			userPhoneNumber,
			role,
			services,
			image,
			autoGeneratePassword,
			currentPassword,
			newPassword,
			newPasswordRepeat,
			sex,
			dateOfBirth
		);
		if (result.OK) {
			dispatch(success(result.Data));
			if (dashboardPage && isArray(result.Data)) {
				dispatch(
					updateUserAttributes(
						result.Data.find((user) => user.id === userId),
						true
					)
				);
			}
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: workerConstants.EDIT_WORKER_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.EDIT_WORKER_SUCCESS, result };
	}
	function failure() {
		return { type: workerConstants.EDIT_WORKER_FAILURE };
	}
}

export function deleteWorker(workerId) {
	return async (dispatch) => {
		dispatch(request());

		let result = await workerService.deleteWorker(workerId);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: workerConstants.DELETE_WORKER_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.DELETE_WORKER_SUCCESS, result };
	}
	function failure() {
		return { type: workerConstants.DELETE_WORKER_FAILURE };
	}
}

export function resetWorkerState() {
	return { type: workerConstants.RESET_WORKER_STATE };
}

export function saveAutomaticConfirmationParameter(workerId, switchValue) {
	return async (dispatch) => {
		dispatch(request());
		const req = {
			workerId: workerId,
			automaticAppointment: switchValue
		};
		let result = await workerService.saveAutomaticConfirmationParameter(req);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: workerConstants.SAVE_NOFITICATION_PARAM_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.SAVE_NOFITICATION_PARAM_SUCCESS, result };
	}
	function failure() {
		return { type: workerConstants.SAVE_NOFITICATION_PARAM_FAILURE };
	}
}

export function automaticRefreshWorkingTimeAction(userId, automaticRefreshWorkingTime) {
	return async (dispatch) => {
		dispatch(request());
		let result = await workerService.automaticRefreshWorkingTime(userId, automaticRefreshWorkingTime);
		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure(result.ErrorText));
		}
	};

	function request() {
		return { type: workerConstants.AUTOMATIC_REFRESH_WORKING_TIME_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.AUTOMATIC_REFRESH_WORKING_TIME_SUCCESS, result };
	}
	function failure(message) {
		return { type: workerConstants.AUTOMATIC_REFRESH_WORKING_TIME_FAILURE, message };
	}
}

export function checkUserEmail(email) {
	return async (dispatch) => {
		dispatch(request());

		let result = await workerService.checkUserEmail(email);

		if (result.OK) {
			dispatch(success(result.message));
		} else {
			dispatch(failure(result.ErrorText));
		}
	};

	function request() {
		return { type: workerConstants.CHECK_USER_EMAIL_REQUEST };
	}
	function success(message) {
		return { type: workerConstants.CHECK_USER_EMAIL_SUCCESS, message };
	}
	function failure(message) {
		return { type: workerConstants.CHECK_USER_EMAIL_FAILURE, message };
	}
}

export function sendUserInvite(email, companyId) {
	return async (dispatch) => {
		dispatch(request());

		let result = await workerService.sendUserInvite(email, companyId);

		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure());
		}
	};

	function request() {
		return { type: workerConstants.SEND_USER_INVITE_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.SEND_USER_INVITE_SUCCESS, result };
	}
	function failure() {
		return { type: workerConstants.SEND_USER_INVITE_FAILURE };
	}
}

export function attachUserToCompany(userHash) {
	return async (dispatch) => {
		dispatch(request());

		let result = await workerService.attachUserToCompany(userHash);

		if (result.OK) {
			dispatch(success(result.Data));
		} else {
			dispatch(failure(result.ErrorText));
		}
	};

	function request() {
		return { type: workerConstants.ATTACH_USER_TO_COMPANY_REQUEST };
	}
	function success(result) {
		return { type: workerConstants.ATTACH_USER_TO_COMPANY_SUCCESS, result };
	}
	function failure(message) {
		return { type: workerConstants.ATTACH_USER_TO_COMPANY_FAILURE, message };
	}
}

export function getNotes(clientId, workerId) {
	return async (dispatch) => {
		dispatch(getNotesRequest());
		let result = await workerService.getNotes(clientId, workerId);
		if (result.OK) {
			dispatch(getNotesSuccess(result.Data));
		} else {
			dispatch(getNotesFailure());
		}
	};

	function getNotesRequest() {
		return {
			type: workerConstants.GET_NOTES_REQUEST
		};
	}

	function getNotesSuccess(result) {
		return {
			type: workerConstants.GET_NOTES_SUCCESS,
			result
		};
	}

	function getNotesFailure() {
		return {
			type: workerConstants.GET_NOTES_FAILURE
		};
	}
}

export function setNotes(notes, clientId) {
	return async (dispatch) => {
		dispatch(setNotesRequest());
		let result = await workerService.setNotes(notes, clientId);
		if (result.OK) {
			dispatch(setNotesSuccess(result.Data));
		} else {
			dispatch(setNotesFailure());
		}
	};

	function setNotesRequest() {
		return {
			type: workerConstants.SET_NOTES_REQUEST
		};
	}

	function setNotesSuccess(result) {
		return {
			type: workerConstants.SET_NOTES_SUCCESS,
			result
		};
	}

	function setNotesFailure() {
		return {
			type: workerConstants.SET_NOTES_FAILURE
		};
	}
}

export function resetNotes() {
	return { type: workerConstants.RESET_NOTES };
}
