export const globalConstants = {
	LOCAL_STORAGE_USER: 'bookAllUser',
	DEFAULT_LANGUAGE: 'srb',
	DATE_FORMAT: 'YYYY-MM-DD',
	DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
	DATE_TIME_FORMAT_SRB: 'DD.MM.YYYY HH:mm',
	DATE_FORMAT_SRB: 'DD.MM.YYYY',
	DATE_FORMAT_SRB_dd: 'dd.MM.yyyy',
	TIME_FORMAT: 'HH:mm',
	BOOKALL_MAIL: 'info@bookall.rs',
	GOOGLE_CLIENT_ID: '819319801856-87uvs86fbffvclqbj91asnv0cemh1umd.apps.googleusercontent.com',
	REGULAR_LOGIN: 'regular',
	GOOGLE_LOGIN: 'google',
	LOGIN: 'login',
	REGISTER: 'register',
	APPLE_CLIENT_ID: 'com.bookall.apple.signin',
	APPLE_REDIRECT_URI: 'https://admin.bookall.rs/return',
	APPLE_SCOPE: 'email%20name',
	APPLE_RESPONSE_MODE: 'form_post',
	APPLE_RESPONSE_TYPE: 'code+id_token',
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'month',
	DOWN: 'down',
	UP: 'up',
	SVE_USLUGE: 'Sve usluge',
	BOOKALL_COLOR: '#00a4ae',
	YELLOW_COLOR: '#FDC60A',
	RED_COLOR: '#e54b4b'
	// change for production !!!
	// LOCAL_STORAGE_USER: 'bookAllUser'
};
