import axios from 'axios';
import { createOKResponse, createErrorResponse } from './responses';
import config, { REST_METHOD } from './config';
import { getUser, getItem, setItem, isNull } from '../helpers';
import { globalConstants } from '../constants';

export async function login(email, password) {
	try {
		const response = await axios({
			method: 'post',
			url: `${config.url}/login`,
			data: { email, password, platform: 'web' }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function googleLogin(googleAccessCode) {
	try {
		const response = await axios({
			method: 'post',
			url: `${config.url}/login`,
			data: { googleAccessCode, platform: 'web' }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function appleLogin(appleIdToken) {
	try {
		const response = await axios({
			method: 'post',
			url: `${config.url}/login`,
			data: { appleIdToken, platform: 'web' }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function signUp(userName, userEmail, password, passwordRepeat, language, promoCode) {
	try {
		const response = await axios({
			method: 'post',
			url: `${config.url}/user/add`,
			data: { userName, userEmail, password, passwordRepeat, language, type: 'web', promoCode }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function signUpGoogle(googleAccessCode, promoCode) {
	try {
		const response = await axios({
			method: 'post',
			url: `${config.url}/user/add`,
			data: { googleAccessCode, type: 'web', promoCode }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function signUpApple(appleIdToken, appleUser, promoCode) {
	try {
		const response = await axios({
			method: 'post',
			url: `${config.url}/user/add`,
			data: { appleIdToken, appleUser, type: 'web', promoCode }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function deleteUserAccount() {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/user/delete`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.delete_user_error');
	}
}

export async function resetPassword(email) {
	try {
		const language = getItem('lng');
		const response = await axios({
			method: 'post',
			url: `${config.url}/user/resetPassword`,
			data: {
				email,
				language: language ? language : globalConstants.DEFAULT_LANGUAGE
			}
		});
		return createOKResponse(response.status, response.data, true);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function verifyAccount(userHash) {
	try {
		const response = await axios({
			method: 'post',
			url: `${config.url}/user/activate`,
			data: {
				userHash
			}
		});
		return createOKResponse(response.status, response.data, true);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function getTermsFile() {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				Authorization: token
			},
			url: `${config.url}/terms/get`
		});
		return createOKResponse(response.status, response.data, true);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function acceptTerms(version) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'POST',
			headers: {
				Authorization: token
			},
			url: `${config.url}/terms/accept`,
			data: {
				version: version
			}
		});
		return createOKResponse(response.status, response.data, true);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function getToken() {
	try {
		const user = getUser();
		if (!user) {
			return null;
		} else {
			const expirationDate = new Date(user.expirationDate);
			if (!expirationDate || expirationDate <= new Date()) {
				const response = await axios({
					method: 'post',
					headers: {
						Authorization: user.token
					},
					url: `${config.url}/token/refresh`
				});
				const responseData = createOKResponse(response.status, response.data, true);
				if (responseData.OK && responseData.Data && responseData.Data.token) {
					user.token = responseData.Data.token;
					user.expirationDate = responseData.Data.tokenExpire * 1000;
					setItem(globalConstants.LOCAL_STORAGE_USER, user);
					return responseData.Data.token;
				} else {
					throw new Error('Session expired');
				}
			} else {
				return user.token;
			}
		}
	} catch (error) {
		return createErrorResponse(440, 'Session expired');
	}
}

export const updateLanguage = async (language, userId) => {
	return callAndCheckResponse('/user/setLanguage', REST_METHOD.POST, 'error.update_language_error', null, {
		userId,
		language
	});
};

export const callAndCheckResponse = async (url, method, errorMessage, validator, data, dontShowToast) => {
	try {
		const token = await getToken();
		const response = await axios({
			method: method,
			headers: {
				Authorization: token
			},
			url: `${config.url}${url}`,
			data
		});
		const validResponse = isNull(validator) || validator(response);

		return validResponse
			? createOKResponse(
					response.status,
					{
						responseData: response.data
					},
					dontShowToast
			  )
			: createErrorResponse({ message: errorMessage }, errorMessage);
	} catch (error) {
		return createErrorResponse(error, errorMessage);
	}
};

export async function upgradeRole() {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'POST',
			headers: {
				Authorization: token
			},
			url: `${config.url}/user/clientToCompanyAdmin`
		});
		return createOKResponse(response.status, response.data, true);
	} catch (error) {
		return createErrorResponse(error);
	}
}
