import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
// routes config
import { getRoutes } from '../routes';
import { withNamespaces } from 'react-i18next';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const TheContent = ({ t }) => {
	const { roleName } = useSelector((state) => state.authentication);
	return (
		<main className="c-main">
			<div className="container-fluid">
				<Suspense fallback={loading}>
					<Switch>
						{roleName &&
							getRoutes(roleName, t).map((route, idx) => {
								return (
									route.component && (
										<Route
											key={idx}
											path={route.path}
											exact={route.exact}
											name={route.name}
											render={(props) => (
												<div className="fade show">
													<route.component {...props} />
												</div>
											)}
										/>
									)
								);
							})}
						{/* <Redirect from="/" to="/dashboard" /> */}
					</Switch>
				</Suspense>
			</div>
		</main>
	);
};

export default withNamespaces()(TheContent);
