import React from 'react';
import { useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { sideBarConstants } from '../../../constants';

const SecondStepComponent = ({ t }) => {
	const dispatch = useDispatch();

	return (
		<div className="steps-wizzard__content">
			<span>{t('label.after_registration')}</span>
			<NavLink
				to="/company"
				onClick={() => {
					dispatch({ type: sideBarConstants.INFO_BOX_OPEN, open: false });
				}}>
				{t('label.stores').toUpperCase()}
			</NavLink>
			<span>{t('label.by_clicking_on_button')}</span>
		</div>
	);
};

export default withNamespaces()(SecondStepComponent);
