import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';

// sidebar nav config
import { getNavigations } from './_nav';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classnames, isMobileDeviceWidth } from '../helpers';
import { sideBarConstants } from '../constants';

const TheSidebar = ({ t }) => {
	const dispatch = useDispatch();
	const sidebarShow = useSelector((state) => state.changeState.sidebarShow);
	const { roleName } = useSelector((state) => state.authentication);
	const sideBarClassName = classnames(
		'c-sidebar',
		'c-sidebar-dark',
		'c-sidebar-fixed',
		sidebarShow && !isMobileDeviceWidth() && 'c-sidebar-lg-show',
		sidebarShow && isMobileDeviceWidth() && 'c-sidebar-show'
	);

	return (
		<div
			className={sideBarClassName}
			onClick={() => isMobileDeviceWidth() && dispatch({ type: sideBarConstants.OPEN_CLOSE_SIDE_BAR })}
			id="sidebar">
			<div className="c-side-nav__book-all">
				<h6>BookAll</h6>
			</div>
			{roleName && (
				<ul className="c-sidebar-nav h-100" style={{ position: 'relative' }}>
					{getNavigations(roleName, t).map((nav, index) => (
						<li key={index} className="c-sidebar-nav-item">
							<NavLink activeClassName="active" className="c-sidebar-nav-link" to={nav.to}>
								<FontAwesomeIcon className="c-sidebar-nav-icon" icon={nav.icon} />
								<label>{t(nav.name)}</label>
							</NavLink>
						</li>
					))}
					<div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
						<div className="ps__thumb-x" style={{ left: '0px', width: '0px' }}></div>
					</div>
					<div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
						<div className="ps__thumb-y" style={{ top: '0px', height: '0px' }}></div>
					</div>
				</ul>
			)}
		</div>
	);
};

export default withNamespaces()(React.memo(TheSidebar));
