import React from 'react';
import { withNamespaces } from 'react-i18next';

const CheckmarkComponent = ({ t }) => {
	return (
		<span className="checkmark">
			<div className="checkmark_stem"></div>
			<div className="checkmark_kick"></div>
		</span>
	);
};

export default withNamespaces()(CheckmarkComponent);
