import React from 'react';
import { useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { sideBarConstants } from '../../../constants';

const ThirdStepComponent = ({ t }) => {
	const dispatch = useDispatch();

	return (
		<div className="steps-wizzard__content">
			<span>{t('label.do_not_forget_to_add_services')}</span>
			<NavLink
				to="/services"
				onClick={() => {
					dispatch({ type: sideBarConstants.INFO_BOX_OPEN, open: false });
				}}>
				{t('label.services').toUpperCase()}
			</NavLink>
		</div>
	);
};

export default withNamespaces()(ThirdStepComponent);
