export const notificationInfoOptions = {
	hideProgressBar: true,
	autoClose: 5000,
	className: 'info-notification',
	bodyClassName: 'info-body'
};

export const notificationSuccessOptions = {
	hideProgressBar: true,
	autoClose: 5000,
	className: 'success-notification',
	bodyClassName: 'success-body'
};

export const notificationErrorOptions = {
	hideProgressBar: true,
	autoClose: 5000,
	className: 'error-notification',
	bodyClassName: 'error-body'
};
