import axios from 'axios';
import { createOKResponse, createErrorResponse } from './responses';
import config from './config';
import { getToken } from './authService';
import { getItem } from '../helpers';
import { globalConstants } from '../constants/globalConstants';

export async function getWorkers(companyId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/user/get-all?companyId=${companyId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.get_users_failed');
	}
}

export async function addWorker(companyId, userName, userDescription, userEmail, userPhoneNumber, role, services, image, sex, dateOfBirth) {
	try {
		const token = await getToken();
		const language = getItem('lng');
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/user/add`,
			data: {
				companyId,
				userName,
				userDescription,
				userEmail,
				userPhoneNumber,
				roleId: role,
				services,
				image,
				language: language ? language : globalConstants.DEFAULT_LANGUAGE,
				sex,
				dateOfBirth
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.add_user_error');
	}
}

export async function editWorker(
	userId,
	companyId,
	userName,
	userDescription,
	userEmail,
	userPhoneNumber,
	role,
	services,
	image,
	autoGeneratePassword,
	currentPassword,
	newPassword,
	newPasswordRepeat,
	sex,
	dateOfBirth
) {
	try {
		const token = await getToken();
		const language = getItem('lng');
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/user/edit`,
			data: {
				userId,
				companyId,
				userName,
				userDescription,
				userEmail,
				userPhoneNumber,
				roleId: role,
				services,
				image,
				autoGeneratePassword,
				currentPassword,
				newPassword,
				newPasswordRepeat,
				language: language ? language : globalConstants.DEFAULT_LANGUAGE,
				sex,
				dateOfBirth
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.edit_user_error');
	}
}

export async function deleteWorker(workerId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/user/delete?userId=${workerId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.delete_user_error');
	}
}

export async function registerUser(password, repeatPassword, userHash) {
	try {
		const response = await axios({
			method: 'post',
			url: `${config.url}/user/webSetNewPassword`,
			data: {
				newPassword: password,
				newPasswordRepeat: repeatPassword,
				userHash
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function getWorker(workerId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/user/get-user?userId=${workerId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.get_employee_failed');
	}
}

export async function savePhotosOfWork(workerId, serviceId, newImages, deleteImages) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/service/add-image`,
			data: {
				serviceId,
				workerId,
				newImages,
				deleteImages
			}
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.save_images_of_work');
	}
}

export async function getWorkerGallery(workerId, serviceId) {
	try {
		const response = await axios({
			method: 'get',
			headers: {
				'Content-Type': 'application/json'
			},
			url: `${config.url}/service/get?workerId=${workerId}&serviceId=${serviceId}`
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.failed_to_fetch_services_images');
	}
}

export async function saveAutomaticConfirmationParameter(request) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/user/automatic-appointment`,
			data: request
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.save_automatic_appointment');
	}
}

export async function checkUserEmail(email) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/user/checkEmail`,
			data: { email: email }
		});
		return createOKResponse(response.status, response.data, true);
	} catch (error) {
		return createErrorResponse(error, 'error');
	}
}

export async function sendUserInvite(email, companyId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/user/sendInvite`,
			data: { email: email, companyId: companyId }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.failed_to_sent_invite');
	}
}

export async function attachUserToCompany(userHash) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/user/attachCompany`,
			data: { userHash: userHash }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.failed_to_add_user_to_company');
	}
}

export async function automaticRefreshWorkingTime(userId, automaticRefreshWorkingTime) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token
			},
			url: `${config.url}/user/automatic-refresh-working-time`,
			data: { userId, automaticRefreshWorkingTime }
		});
		return createOKResponse(response.status, response.data);
	} catch (error) {
		return createErrorResponse(error, 'error.failed_to_add_user_to_company');
	}
}

export async function getNotes(clientId, workerId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'get',
			headers: {
				Authorization: token
			},
			url: `${config.url}/notes/worker/get?clientId=${clientId}&workerId=${workerId}`
		});
		return createOKResponse(response.data.responseCode, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}

export async function setNotes(notes, clientId) {
	try {
		const token = await getToken();
		const response = await axios({
			method: 'post',
			headers: {
				Authorization: token
			},
			url: `${config.url}/notes/worker/insert`,
			data: {
				notes,
				clientId
			}
		});
		return createOKResponse(response.data.responseCode, response.data);
	} catch (error) {
		return createErrorResponse(error);
	}
}
