export const categoryConstants = {
	GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
	GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
	GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',

	DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
	DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
	DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

	ADD_CATEGORY_REQUEST: 'ADD_CATEGORY_REQUEST',
	ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
	ADD_CATEGORY_FAILURE: 'ADD_CATEGORY_FAILURE',

	EDIT_CATEGORY_REQUEST: 'EDIT_CATEGORY_REQUEST',
	EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
	EDIT_CATEGORY_FAILURE: 'EDIT_CATEGORY_FAILURE'
};
