import { callAndCheckResponse } from './authService';
import { REST_METHOD } from './config';
import { isNull } from '../helpers';

// *** userID je id onog sa kim se dopisuje, senderId se izvlaci iz tokena **
export const getAllMessages = async () => {
	return callAndCheckResponse(
		'/messages/get-all',
		REST_METHOD.GET,
		'error.get_all_messages',
		(response) => !isNull(response.data) && !isNull(response.data)
	);
};

// clientId = 5
// ako je clientId = senderId => sender: client, suprotno sender: worker)
/* when we open the chat we have to get all messages for selected chat */
export const getAllMessagesPerUserId = async (userId, currentPage = 1) => {
	return callAndCheckResponse(
		`/messages/get-all-per-user?userId=${userId}&sort=DESC&page=${currentPage}&itemsPerPage=10`,
		REST_METHOD.GET,
		'error.get_all_messages_per_user',
		(response) => !isNull(response.data) && !isNull(response.data)
	);
};

export const sendMessage = async (userId, message) => {
	return callAndCheckResponse(
		'/messages/sendMessage',
		REST_METHOD.POST,
		'error.send_message',
		(response) => !isNull(response.data) && !isNull(response.data),
		{
			userId,
			messageText: message
		},
		true
	);
};

// set chat to be read
export const markChatAsRead = async (userId) => {
	return callAndCheckResponse(
		`/messages/mark-as-read?userId=${userId}`,
		REST_METHOD.GET,
		'',
		(response) => !isNull(response.data) && !isNull(response.data),
		null,
		true
	);
};
