import { toast } from 'react-toastify';
import i18n from 'i18next';
import { notificationErrorOptions, notificationSuccessOptions } from '../views/components/NotificationConstants';

export function createOKResponse(statusCode, data, dontShowToast) {
	if (data.responseCode && data.responseCode !== 200) {
		return createErrorResponse(data.responseCode, data.message, dontShowToast);
	}
	!dontShowToast && data.message && toast.success(i18n.t(data.message), notificationSuccessOptions);
	return {
		OK: true,
		StatusCode: 200,
		Data: data.responseData,
		numbersOfData: data.result,
		message: data.message
	};
}

export function createErrorResponse(errorCode, message, dontShowToast) {
	!dontShowToast && toast.error(i18n.t(message), notificationErrorOptions);
	return {
		OK: false,
		StatusCode: '',
		ErrorText: message,
		ErrorCode: errorCode
	};
}
