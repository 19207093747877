import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../actions';
import { withNamespaces } from 'react-i18next';
import Select from 'react-select';
import i18next from 'i18next';
import { setItem, getLanguages, getItem } from '../helpers';
import { updateLanguage } from '../services/authService';
import mainLogo from '../assets/images/main logo.svg';
import { googleLogout } from '@react-oauth/google';
import { globalConstants, sideBarConstants } from '../constants';
import { Link } from 'react-router-dom';

const TheHeader = ({ t }) => {
	const dispatch = useDispatch();
	const { userAttributes, authType } = useSelector((state) => state.authentication);

	const languages = getLanguages();
	const language = getItem('lng');
	const [selectedLanguage, setSelectedLanguage] = useState(language ? languages.find((l) => l.value === language) : languages[0]);

	const toggleSidebar = () => dispatch({ type: sideBarConstants.OPEN_CLOSE_SIDE_BAR });

	const handleOnChangeLanguage = (option) => {
		if (option.value !== selectedLanguage.value) {
			const lngValue = option.value;
			i18next.changeLanguage(lngValue);
			setSelectedLanguage(option);
			updateLanguage(lngValue, userAttributes.id);
			setItem('lng', lngValue);
		}
	};

	const handleLogout = () => {
		authType === globalConstants.GOOGLE_LOGIN && googleLogout();
		dispatch(logout());
	};

	return (
		<header className="c-header c-header-light c-header-fixed c-header-with-subheader">
			<div className="logo-wrapper" to="/">
				<img className="logo" src={mainLogo} alt="logo" />
			</div>
			<button onClick={toggleSidebar} className="c-header-toggler ml-md-3 d-lg-none" type="button">
				<span className="c-header-toggler-icon"></span>
			</button>
			<button onClick={toggleSidebar} className="c-header-toggler ml-3 d-md-down-none" type="button">
				<span className="c-header-toggler-icon"></span>
			</button>
			<ul className="d-md-down-none mr-auto c-header-nav">
				<Link className="c-header-nav-link" to="/dashboard">
					BookAll - {t('label.welcome')} {userAttributes && userAttributes.userName}
				</Link>
			</ul>
			<div className="header-logout">
				<Select
					className="language-select"
					placeholder={t('label.select...')}
					options={languages}
					onChange={handleOnChangeLanguage}
					value={selectedLanguage}
				/>
				<div className="c-header-nav-link" onClick={handleLogout}>
					{t('label.logout')}
				</div>
			</div>
		</header>
	);
};

export default withNamespaces()(TheHeader);
